<template>
  <div id="customer-dashboard">
    
      <v-container class="pr-4 pl-4 pt-1" fluid="fluid" grid-list-md="grid-list-md">
        <v-tabs v-model="tabModel"  left class="mb-3"  slider-color="blue">
            <v-tab :href="`#tab-personal`">Dashboard</v-tab>
            <v-tab :href="`#tab-identification`">Listings</v-tab>
          </v-tabs>
        <v-layout wrap="wrap">
          <v-flex xs12="xs12">
            <h2 class="display-1 mb-1">Dashboard</h2>
          </v-flex>
          <v-flex xs12="xs12" md6="md6">
            <v-layout wrap="wrap">
              <v-flex v-for="stat in stats" :key="stat.label" xs6="xs6">
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div class="display-1 mb-2">{{ stat.number }}</div>{{ stat.label }}
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md6="md6">
            <v-card height="100%">
              <v-card-title class="">Tasks</v-card-title>
              <v-data-table class="mt-1" :items="tasks" hide-headers="hide-headers" hide-actions="hide-actions">
                <template slot="items" slot-scope="props">
                  <td>
                    <v-checkbox @click="clickDeleteTask(props.item)"></v-checkbox>
                  </td>
                  <td>{{ props.item.title }}</td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex xs12="xs12">
            <v-card>
              <v-card-title class="">New Leads
                <v-spacer></v-spacer>
                <v-text-field v-model="newLeadsSearch" append-icon="search" label="Search"></v-text-field>
              </v-card-title>
              <v-data-table :headers="newLeadsHeaders" :items="newLeads" :search="newLeadsSearch">
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.firstName }} {{ props.item.lastName }}</td>
                  <td>{{ props.item.email }}</td>
                  <td>{{ props.item.company }}</td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </div>
</template>

<style>
h1, h2, h3 {
  color: #485468;}

#avatar {
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ddd;
  width: 200px;
  height: 200px;}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import customerService from '@/services/customer-service'

export default {
  components: {

  },
  data() {
    return {
      stats : [
  {
    number: '42',
    label: 'New leads this week',
  },
  {
    number: '$8,312',
    label: 'Sales this week',
  },
  {
    number: '233',
    label: 'New leads this month',
  },
  {
    number: '$24,748',
    label: 'Sales this month',
  },
]


    }
  },

  created() {                             
  },

  computed: {
    
    ...mapState([
      'appState',
    ]),

   
  },
  methods: {
   
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    async init() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

  }
}
</script>
