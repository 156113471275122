
<template>
  <div id="setup">
    <!-- Dialogs -->
    <v-dialog v-model="isUnexpectedError" persistent width="400">
      <v-card dark>
        <v-card-title class="headline">An unexpected error has occured.</v-card-title>

        <v-card-text>Please check your internet connection and refresh the page. If problem persist contact administrator.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  flat="flat" :to="'/login'">
            Back to Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pageLoading" persistent width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{pageLoadingMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unVerifiedDialog" persistent max-width="340">
      <v-card dark>
        <v-card-title class="headline">Unable to verify Token</v-card-title>

        <v-card-text>Please ensure you clicked the lastest link sent to your email</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  flat="flat" :to="'/login'">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verifiedDialog" persistent max-width="340">
      <v-card dark color="blue darken-2">
        <v-card-title class="headline">Password has been reset.</v-card-title>

        <v-card-text>Your password request has been granted. Your new Password has been sent to your email.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  flat="flat" :to="'/login'">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
#setup {
  padding:40px}
ul{
  list-style: none;
  margin-left: -20px;}
  ul b {
    color: #444;}
.stepper-footer {
  float: right}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import AuthService from "@/services/authentication-service"
import validations from '@/common/validator'
import { timeout } from 'q';

export default {
  data() {
    return {
      loading: false,
      pageLoading: false,
      pageLoadingMessage: '',
      isVerified: false,
      verifiedDialog: false,
      unVerifiedDialog: false,
      isUnexpectedError: false,
      timeout: null,
    }
  },

  created() {
    this.init()
  },

  beforeDestroy() {
    clearTimeout(timeout)
  },

  computed: {
    ...mapState([
      'appState',
      'commonState'
    ])
  },

  methods: {
    ...mapGetters([
      'getTimezonesAndOffsets',
    ]),

    async init() {
      try {
        await this.verifyToken()
      } catch (e) {
        this.isUnexpectedError = true
      }
    }, 


    async verifyToken() {
      let token = this.$route.params.token
      this.pageLoadingMessage = "Verifying Token Please wait..."
      this.pageLoading = true
      try {
        let result = await AuthService.verifyForgottenPassword(token)
        this.isVerified = result.data
        if(!this.isVerified) {
          this.unVerifiedDialog = true
          return
        } 

        this.pageLoading = false
        this.verifiedDialog = true
      
      } catch (e) {
        console.log(e)
        if(e.response && e.response.status === 404) {
          this.unVerifiedDialog = true
          return
        }
        this.isUnexpectedError = true
      } finally {
        this.pageLoading = false
      }
    },

  },

  
  
}
</script>
