import _ from 'lodash'
export default {
  data() {
    return {
      globalSearch: '',
      items: [],
      totalCount: 0,
      skip: 0,
      limit: 5,
      pagination: {},
      sortString:'',
      searchFunc: null
    }
  },
  created() {
    this.searchFunc = _.debounce(this.fetchData, 500)
  },
  watch: {
    pagination: async function () {
        const { sortBy, descending, page, rowsPerPage } = this.pagination
        if(sortBy) {
          this.sortString = `&sort=${sortBy}:${descending ? 'desc': 'asc'}`
        }
        this.skip = page == 1 ? 0 : (page - 1) * rowsPerPage
        this.limit= rowsPerPage
        
        await this.fetchData() 
    }
  },

  methods: {
    onSearch() {
      return this.searchFunc()
    },
  }
}