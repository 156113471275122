<template>
  <div class="px-5">
    <v-dialog @click="close" v-model="isDialogOpen" persistent transition="dialog-bottom-transition" width="800">
      <v-card flat>
        
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{formTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="saveNotificationSetting">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="px-4 pt-4">
          <v-layout column>
            <v-layout wrap mt-4>

              <v-flex xs12>
                <v-text-field
                  :disabled="true"
                  v-validate="'required'" 
                  data-vv-scope="settings"
                  data-vv-name="process"
                  :error-messages="errors.collect('settings.process')"
                  v-model="notificationSetting.process"  
                  label="Select Process" 
                />
              </v-flex>

              <v-flex xs6>
                <v-checkbox label="Enable/Disable Staff Notifications" class="mt-0" v-model="notificationSetting.is_user"/>
              </v-flex>

              <v-flex xs6>
                <v-checkbox label="Enable/Disable Customer Notifications" class="mt-0" v-model="notificationSetting.is_customer"/>
              </v-flex>

            </v-layout>

            <v-layout v-if="notificationSetting.is_user">
              <v-flex>
                <v-select
                  :items="staffUsers"
                  :item-text="`full_name`"
                  :item-value="`ID`"
                  label="Staff Users"
                  placeholder=""
                  v-model="notificationSetting.users"
                  v-validate="'required'"
                  data-vv-scope="settings"
                  data-vv-name="users"
                  data-vv-as="Staff User"
                  :error-messages="errors.collect('settings.users')"
                  box
                  multiple
                />
              </v-flex>
            </v-layout>

            <v-layout wrap mt-4 mb-4>
              <v-flex xs12 mb-2>
                <b>Email Settings</b>
              </v-flex>

              <v-flex xs6 pr-2>
                <v-text-field 
                  label="Subject"
                  placeholder=" "
                  v-model="notificationSetting.email_header"
                  v-validate="'required'"
                  data-vv-scope="settings"
                  data-vv-name="email_header"
                  data-vv-as="Email Subject"
                  :error-messages="
                  errors.collect('settings.email_header')
                  "
                  box
                />
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field 
                  label="Body"
                  placeholder=" "
                  v-model="notificationSetting.email_body"
                  v-validate="'required'"
                  data-vv-scope="settings"
                  data-vv-name="email_body"
                  data-vv-as="Email Body"
                  :error-messages="
                  errors.collect('settings.email_body')
                  "
                  box
                />
              </v-flex>

              <v-flex>
                <v-checkbox label="Enable/Disable Email Notifications" class="mt-0" v-model="notificationSetting.email_is_active"/>
              </v-flex>
            </v-layout>


            <v-layout wrap mb-4>
              <v-flex xs12 mb-3>
                <b>SMS Settings</b>
              </v-flex>

              <v-flex xs6 pr-2>
                <v-text-field
                  label="Subject"
                  v-model="notificationSetting.sms_header"
                  v-validate="'required'"
                  data-vv-scope="settings"
                  data-vv-name="sms_header"
                  data-vv-as="SMS Subject"
                  placeholder=" "
                  :error-messages="
                  errors.collect('settings.sms_header')
                  "
                  box
                />
              </v-flex>
              <v-flex xs6 pl-2>
                <v-text-field 
                  label="Body"
                  v-model="notificationSetting.sms_body"
                  v-validate="'required'"
                  data-vv-scope="settings"
                  data-vv-name="sms_body"
                  data-vv-as="SMS Body"
                  placeholder=" "
                  :error-messages="
                    errors.collect('settings.sms_body')
                  "
                  box
                />
              </v-flex>

              <v-flex>
                <v-checkbox label="Enable/Disable SMS Notifications" class="mt-0" v-model="notificationSetting.sms_is_active"/>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bodyDialog" transition="dialog-bottom-transition" width="600">
      <v-card>
          <v-btn color="secondary" flat icon dark @click="bodyDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        <v-card-title>
          <div style="padding-bottom: 8px; border-bottom: 1px solid #cccc; width: 100%;">
            {{ bodyToDisplayTitle }} 
          </div>
        </v-card-title>
        <v-card-text>
          {{ bodyToDisplay }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="pa-2 pl-4 mb-3 elevation-0">
      <h2>
        Notification Settings
      </h2>
    </v-card>
    
    <v-tabs v-model="currentTab">
      <v-tab v-for="app in apps" :key="app" :href="`#${app}`">
        {{ app }}
      </v-tab>
    </v-tabs>

    <v-toolbar  class="elevation-0 pt-4" color="white">
       <v-text-field
        v-model="globalSearch"
        class="va-form-control"
        append-icon="search"
        label=""
        @keyup="onSearch()"
        solo
        placeholder="Search"
     />
     <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    
      <!-- <v-btn color="primary" dark @click="openDialog()" class="mb-2">New {{ this.currentTab }} Settings</v-btn> -->
      
    </v-toolbar>

    <v-card flat>
      <v-card-text>
        <v-layout>
          <v-flex>
            <v-data-table
              class="elevation-2"
              :headers="headers"
              :items="items"
              :loading="isLoading"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.process }}</td>
                <td> <v-icon @click="viewBody(props.item.email_body, props.item.process, 'Email Body')"> fa-eye </v-icon> </td>
                <td> <v-icon @click="viewBody(props.item.sms_body, props.item.process, 'SMS Body')"> fa-eye </v-icon> </td>

                <td>
                  <v-chip small :color="props.item.is_user ? 'green' : ''" :text-color=" props.item.is_user ? 'white' : ''">
                    {{ props.item.is_user ? 'Enabled' : 'Disabled' }}
                  </v-chip>
                </td>

                <td>
                  <v-chip small :color="props.item.is_customer ? 'green' : ''" :text-color=" props.item.is_customer ? 'white' : ''">
                    {{ props.item.is_customer ? 'Enabled' : 'Disabled' }}
                  </v-chip>
                </td>

                <td>
                  <v-chip small :color="props.item.email_is_active ? 'green' : ''" :text-color=" props.item.email_is_active ? 'white' : ''">
                    {{ props.item.email_is_active ? 'Enabled' : 'Disabled'}}
                  </v-chip>
                </td>

                <td>
                  <v-chip small :color="props.item.sms_is_active ? 'green' : ''" :text-color=" props.item.sms_is_active ? 'white' : ''">
                    {{ props.item.sms_is_active ? 'Enabled' : 'Disabled'}}
                  </v-chip>
                </td>

                <td>
                  <v-icon small class="mr-2" @click="openDialog(props.item)">
                    edit
                  </v-icon>
                  <!-- <v-icon small @click="deleteItem(props.index, props.item)">
                    delete
                  </v-icon> -->
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import companyService from "@/services/company-service";
import notificationService from "@/services/notification-service";
import UserService from "@/services/user-service";

export default {
  data(){
    return {
      isLoading: false,
      currentTab: 'Loan-Pro',
      apps: [],
      headers: [
        { text: 'Processes', sortable: true, value: 'process'},
        { text: 'email body,', sortable: true, value: 'email_body'},
        { text: 'SMS body ', sortable: true, value: 'sms_body'},
        { text: 'Staff Notification Status', sortable: false, value: 'is_user'},
        { text: 'Customer Notification Status ', sortable: false, value: 'is_customer'},
        { text: 'Email Notification Status ', sortable: false, value: 'email_is_active'},
        { text: 'SMS Notification Status ', sortable: false, value: 'sms_is_active'},
        { text: 'Actions', value: ''},
      ],
      notificationSettings: [],
      processes: [
        "A Process"
      ],
      notificationSetting: {
        module:"Loan-Pro",	
        email_body: "",
        email_header: "",
        email_is_active: true,
        sms_body: "",
        sms_header: "",
        sms_is_active: true,
        process: "Loan disbursement",

        users: [],
        is_user: true,
        is_customer: false
      },
      isDialogOpen: false,
      formTitle: '',
      globalSearch: '',
      bodyToDisplay: '',
      bodyToDisplayTitle: '',
      bodyDialog: false,
      staffUsers: []
    }
  },

  computed: {
    items(){
      if(Array.isArray(this.notificationSettings) && this.notificationSettings.length > 0) {
        return this.notificationSettings.filter(notification => notification.module == this.currentTab);
      } else {
        return []
      }
    },
  },

  async beforeMount(){
    let notificationResult = await notificationService.getNotificationSettings();
    this.notificationSettings = notificationResult;
    let res = await UserService.findAll();
    this.staffUsers = res.data.filter(user => user.full_name != "system admin");
    let result = await companyService.fetchModules();
    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        const app = result[key];
        this.apps.push(app.app_name);
      }
    }
  },

  methods: {
    openDialog(item){
      if(item) {
        this.notificationSetting = {...item};
      } else {
        this.notificationSetting = {
          module: this.currentTab,
          email_body: "",
          email_header: "",
          email_is_active: false,
          sms_body: "",
          sms_header: "",
          sms_is_active: false,
          process: "Loan disbursement",

          users: [],
          is_user: false,
          is_customer: false
        };
      }
      this.formTitle = `${this.notificationSetting.ID ? 'Create' : 'Update'} ${this.currentTab} Settings`
      this.isDialogOpen = true
    },

    async saveNotificationSetting() {
      try {
        let isValid = await this.$validator.validateAll("settings");
        if(isValid) {
          // if(this.notificationSetting._id){
          const result = await notificationService.updateNotificationSettings(this.notificationSetting._id, this.notificationSetting);
          this.notificationSettings = await notificationService.getNotificationSettings();

          // } else {
          //   this.notificationSetting.module = this.cursrentTab;
          //   const result = await notificationService.createNotificationSettings(this.notificationSetting);
          //   console.log(result);
          // }
          this.toast.success('Success', '', {position: 'topCenter'});
          this.isDialogOpen = false;
        } else {
          this.toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
        }
      } catch (error) {
        console.log(error);
      }
    },

    close () {
      this.isDialogOpen = false;
      this.editedItem = Object.assign({}, {
        role_name: '',
        role_description: '',
        access:[]
      })
      this.$validator.reset({scope: 'settings'});
    },

    viewBody(item, process, title){
      this.bodyToDisplay = item;
      this.bodyToDisplayTitle = `${process} - ${title}`;
      this.bodyDialog = true;
    }
  }

}
</script>

<style>

</style>