<template>
 <div>
  <!-- Dialogs -->
   <v-dialog v-model="editSubFactorDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="onSubRiskDialogClosed">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Group Sub Risk Factor Management</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="onEditDialogSaved">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="pa-3">

          <!-- Sub Risk factor table -->
          <h2> <span style=""><v-chip label>{{selectedFactor.risk_factor_name || 'None Selected'}}</v-chip></span> </h2>
          <v-divider class="mb-4"></v-divider>
        
          <va-input v-model="search" :width="'300px'" class="va-form-control" append-icon="search" label="" placeholder="Search"></va-input>
            
            <v-data-table must-sort :loading="riskFactorState.isLoading" class="elevation-1" :search="search" :headers="headers" :items="selectedFactor.sub_risk_factors">
              <template slot="items" slot-scope="props">
                
                <td >
                  <v-edit-dialog   
                    lazy 
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.sub_risk_factor_name }}
                    <v-text-field 
                        slot="input" 
                        v-if="selectedFactor.risk_factor_type !== 'Range'"
                        v-validate="'required|max:250|min:0|max:50'" 
                        data-vv-scope="subFactor"
                        data-vv-name="name"
                        :error-messages="errors.collect('subFactor.name')"
                        v-model="selectSubRiskFactor.sub_risk_factor_name"  
                        label="Edit" 
                        single-line counter></v-text-field>
                  </v-edit-dialog>
                  
                </td>
                <td >
                  <v-edit-dialog  
                    lazy  
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.sub_risk_factor_score + ' %' || '0 %'  }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:0|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="score"
                      :error-messages="errors.collect('subFactor.score')"
                      v-model="selectSubRiskFactor.sub_risk_factor_score"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td>
              
                <td >
                  <v-edit-dialog  
                    lazy 
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.amount + ' %' || '0 %'  }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:-100|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="amount"
                      :error-messages="errors.collect('subFactor.amount')"
                      v-model="selectSubRiskFactor.amount"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td> 
                <td >
                  <v-edit-dialog  
                    lazy 
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.tenure + ' %' || '0 %'  }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:-100|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="tenure"
                      :error-messages="errors.collect('subFactor.tenure')"
                      v-model="selectSubRiskFactor.tenure"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td>
                <td >
                  <v-edit-dialog  
                    lazy
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.interest + ' %' || '0 %'  }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:-100|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="interest"
                      :error-messages="errors.collect('subFactor.interest')"
                      v-model="selectSubRiskFactor.interest"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td>
                <td >
                  <v-edit-dialog  
                    lazy 
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.shares + ' %' || '0 %' }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:-100|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="shares"
                      :error-messages="errors.collect('subFactor.shares')"
                      v-model="selectSubRiskFactor.shares"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td>
                <td >
                  <v-edit-dialog  
                    lazy  
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.dsr + ' %' || '0 %' }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:-100|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="dsr"
                      :error-messages="errors.collect('subFactor.dsr')"
                      v-model="selectSubRiskFactor.dsr"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td>
                <td >
                  <v-edit-dialog  
                    lazy  
                    persistent
                    :style="disableEventStyle"
                    @close="onEditDialogClosed" 
                    @open="onEditDialogOpened(props.item)" 
                    > 
                    {{ props.item.value_collateral_used + ' %' || '0 %' }}
                    <v-text-field slot="input" 
                      v-validate="'required|max_value:100|min_value:-100|decimal:3'" 
                      data-vv-scope="subFactor"
                      data-vv-name="collateral"
                      :error-messages="errors.collect('subFactor.collateral')"
                      v-model="selectSubRiskFactor.value_collateral_used"  
                      label="Edit" 
                      single-line></v-text-field>
                  </v-edit-dialog>
                </td>
              </template>
            </v-data-table>
          </div>
      </v-card>
    </v-dialog>

  <v-dialog v-model="namedDialog" :width="'600px'" persistent>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title>New Risk Factor Group</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="onCloseGroupDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text >
        <va-input
          label="Group Name" 
          v-validate="'required|max:50'" 
          data-vv-scope="groupFactor"
          data-vv-name="name"
          :error-messages="errors.collect('groupFactor.name')"
          placeholder="Enter group name"
          :width="'300px'"
          v-model="editedItem.group_name" 
        ></va-input> 
        <br/>
    
        <v-list >
          <v-subheader>
             <v-checkbox :label="'Select Risk Factors'" @change="onSelectAllRiskFactor($event)"></v-checkbox>  
            <v-spacer></v-spacer>
            <v-text-field placeholder="Search..." style="width: 140px!important" v-model="factorSearch" clearable single-line append-icon="search"></v-text-field>
          </v-subheader>

          <v-divider></v-divider>
          <div style="overflow-y: scroll !important; max-height: 200px; touch-action: manipulation">
            <v-list-tile v-for="item in filterFactors(riskfactors)" :key="item.ID">
              <v-list-tile-action>
                <v-checkbox v-model="item.is_selected"></v-checkbox> 
              </v-list-tile-action>
              <v-list-tile-title>
              <v-list-tile-title>
                {{item.risk_factor_name}} 
                
                <contenteditable 
                  @click="onFactorScoreFocused(item.risk_factor_score)"
                  @returned="validateFactorScore(item)"
                  @blur.native="validateFactorScore(item)"
                  class="content-editable" 
                  tag="span" 
                  :contenteditable="true" 
                  v-model="item.risk_factor_score" 
                  :noNL="true" />
                
              </v-list-tile-title>
                
              </v-list-tile-title>
            </v-list-tile>
          </div>
          
        </v-list>
      </v-card-text>
         <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="" flat @click="onCloseGroupDialog">
          Close
        </v-btn>

        <v-btn color="primary" @click="onSaveFactorGroup">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>



  <!-- Sub Risk factor table -->
  
    <v-card class="pa-2 pl-4 mb-3">
      <h2>Risk Factor Groups</h2>
    </v-card>
    <v-layout row>
      <v-flex xs12 sm8 offset-sm4>
        <va-input v-model="search" :width="'380px'" class="va-form-control" append-icon="search" label="" placeholder="Search"></va-input>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
    <v-layout dense row >
      <v-flex xs12 sm8 offset-sm2>
        <v-card>
          <v-toolbar color="white"  flat >

            <v-toolbar-title>Groups</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn @click="onNamedDialogOpened" color="#eee" icon>
              <v-icon color="#444">add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list>
            <v-list-group  v-for="item in filterGroups(riskFactorState.riskFactorGroups)" :key="item.ID">
              
              <v-list-tile slot="activator">
                <v-list-tile-title class="pl-2"><b class="mr-2">{{ item.group_name }}</b> ({{item.factors.length +'/'+ riskfactors.length}})</v-list-tile-title>

                <v-list-tile-action style="margin-right: -50px !important">
                  <v-menu bottom origin="center center" transition="scale-transition">
                      <v-btn slot="activator" small icon>
                        <v-icon small color="#666" >more_vert</v-icon>
                      </v-btn>
                    <v-list>
                    <v-list-tile @click="onNamedDialogOpenedForEditing(item)" >
                      <v-list-tile-title>Edit</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="onDeleteFactorGroup(item)" >
                      <v-list-tile-title>Delete</v-list-tile-title>
                    </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile v-for="subItem in item.factors" :key="subItem.ID" @click="onSubFactorDialogOpened(item, subItem)">
                <v-list-tile-content>
                  
                  <v-list-tile-title class="pl-3"> 
                    <v-tooltip bottom>
                        <span  slot="activator" style="border-bottom: 1px dashed #444">{{ subItem.risk_factor_name  }}</span>
                      <span>Click to change scores and adjustments</span>
                    </v-tooltip>
                  </v-list-tile-title>
                
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-tooltip bottom>
                    <v-icon slot="activator" color="#aaa" small>mouse</v-icon>
                    <span >Click to change scores and adjustments</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
    

  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

.content-editable {
  font-size: 14px; 
  height: 20px; 
  border-bottom: 1px dashed #444; 
  outline: none;
  min-width: 10px !important; 
  display: inline-block
}

.active {
  background: #eee;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'

export default {
  components: {
    VaInput,
  },

  created () {
    this.initialize()
  },

  beforeDestroy() {
    this.toast.destroy()
  },

  data() {
    return {
      disableEventStyle: '',
      search: '',
      factorSearch: '',
      toast: null,
      namedDialog: false,
      previousValue: null,
      editedItem: {},
      editIndex: -1,
      headers: [
        { text: 'Min Value', align: 'left', sortable: true, value: 'sub_risk_factor_name'},
        { text: 'Score', align: 'left', sortable: true, value: 'sub_risk_factor_score' },
        { text: 'Amount', align: 'left', sortable: true, value: 'amount' },
        { text: 'Tenure', align: 'left', sortable: true, value: 'tenure' },
        { text: 'Interest', align: 'left', sortable: true, value: 'interest' },
        { text: 'Shares', align: 'left', sortable: true, value: 'shares' },
        { text: 'DSR', align: 'left', sortable: true, value: 'dsr' },
        { text: 'Value of Collateral Used', align: 'left', sortable: true, value: 'value_collateral_used' },
     
      ],
      riskfactors: [],     
      loadedRiskFactors: [],

      genrateRangeDialog: false,
      subfactorDialog: false,
      editSubFactorDialog: false,
      editedSubItem: {},
      selectedFactor: {},
      newRangefactor: {},
      subFactorIndex: 0,
      selectSubRiskFactor: {},
      originalSubFactors: {},
      previousSubFactor: {},
      numberOfSelectedFactors: 0,
    }
  },

  computed: {
    ...mapState([
      'appState',
      'riskFactorState'
    ]), 
  },
  
  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG',
      'SET_RISK_FACTORS',
      'UPDATE_SUB_RISK_FACTOR'
    ]),
    ...mapActions([
      'getRiskFactors',
      'createRiskFactorGroup',
      'updateRiskFactorGroup',
      'getRiskFactorGroups',
      'deleteFactorGroup',

      'updateSubRiskFactor',
      'createSubFactor',
      'deleteSubFactor'
    ]),
    
    async initialize () {    
      await this.fetchRiskFactors()
      await this.fetchGroups()
      this.toast = this.$toast
      this.editedItem =  Object.assign({}, this.riskFactorState.defaultGroup)
      this.loadedRiskFactors = [...this.riskFactorState.riskfactors]
      this.editedSubItem =  Object.assign({}, this.riskFactorState.defaultSubRiskFactor)
      this.newRangefactor = Object.assign({}, this.riskFactorState.defaultRangeOptions)
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
    },

    onNamedDialogOpened() {
      this.resetState()
      this.riskfactors = this.riskFactorState.riskfactors.map((el) => {
        el.risk_factor_score  = el.risk_factor_score.toString()
        this.selectAlreadySelectedRiskFactors(el) 
        return el
      }) 
      this.editedItem =  Object.assign({}, this.riskFactorState.defaultGroup)
      this.editedItem.factors = this.riskfactors
      this.namedDialog = true
    },

    onNamedDialogOpenedForEditing(item) {
      this.resetState()
     
      this.editedItem = Object.assign({}, item)
      this.riskFactorState.riskFactorGroups.forEach((el, i)=> {
        if(el.ID == this.editedItem.ID) {
          this.editIndex = i
        }
      })
      this.riskfactors = this.riskFactorState.riskfactors.map((el) => {
        el.risk_factor_score  = el.risk_factor_score.toString()
        this.selectAlreadySelectedRiskFactors(el) 
        return el
      }) 
      this.editedItem.factors = this.riskfactors
      this.namedDialog = true
    },

    selectAlreadySelectedRiskFactors(factor) {
      factor.is_selected = false
      this.editedItem.factors.forEach((el)=>{
        if(el.risk_factor_name.toLowerCase() == factor.risk_factor_name.toLowerCase()) {
          factor.is_selected = true
          factor.risk_factor_score = el.risk_factor_score
        }
        
      })
    },

    onFactorScoreFocused(score) {
      this.previousValue = score
    },

    validateFactorScore(factor) {
      if(isNaN(factor.risk_factor_score) || factor.risk_factor_score === '' ){
        factor.risk_factor_score = this.previousValue
        this.toast.error('Risk factor score must be an integer value. Reverted to previous value', '', {position: 'topCenter'})
        return
      } 
      let value = Number(factor.risk_factor_score)
      if(!Number.isInteger(value)) {
         factor.risk_factor_score = this.previousValue
        this.toast.error('Risk factor score must be an integer value. Reverted to previous value', '', {position: 'topCenter'})
      }
    },

    async fetchRiskFactors() {
      try {
        this.resetState()

        await this.getRiskFactors()
        this.riskfactors = this.riskFactorState.riskfactors.map((el) => {
          el.risk_factor_score  = el.risk_factor_score.toString()
          return el
        }) 
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } 
    },

    async fetchGroups() {
      try {
        this.resetState()
        await this.getRiskFactorGroups()
      } catch(e) {
        console.log('fetching group data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } 
    },

    filterFactors(factors) {
      var app = this
      return factors.filter(function(factor) {
        let search = app.factorSearch ? app.factorSearch.toLowerCase() : ''
        return factor.risk_factor_name.toLowerCase().indexOf(search) != -1
      })
    },

    filterGroups(groups) {
      var app = this
      return groups.filter(function(group) {
        let search = app.search ? app.search.toLowerCase() : ''
        return group.group_name.toLowerCase().indexOf(search) != -1
      })
    },
    
    resetGroupEditDialog() {
      this.SET_RISK_FACTORS({records: this.loadedRiskFactors, total: this.loadedRiskFactors.length})
      this.editedItem = Object.assign({}, this.riskFactorState.defaultGroup)
      this.editIndex = -1
      this.$validator.reset({scope: 'groupFactor'})    
    },

    onCloseGroupDialog() {
      this.resetGroupEditDialog()
      this.namedDialog = false
      this.factorSearch = ''
    },

    onSelectAllRiskFactor(ev) {
      this.riskfactors.forEach((el)=> {
        if(ev) {
          el.is_selected = true
        } else { 
          el.is_selected = false
        }
      })
      this.$forceUpdate()
    },

    doesRecordExist() {
      let items = []
      let existProps = []
      let msg = 'A record already exist with that name '
      if (this.editIndex > -1) {
        items = this.riskFactorState.riskFactorGroups.filter((el)=> {
          return el.ID !== this.editedItem.ID
        })
        items.forEach((el) => {
          if(this.editedItem.group_name.toLowerCase() === el.group_name.toLowerCase()) {
            existProps.push(el.group_name)
          }
        })
      } else {
        items = this.riskFactorState.riskFactorGroups
        items.forEach((el) => {
         if(this.editedItem.group_name.toLowerCase() === el.group_name.toLowerCase()) {
            existProps.push(el.group_name)
          }
        })
      }

      if(existProps.length > 0) {
       this.$toast.warning(msg+existProps.join(', '), '', {position: 'topCenter', timeout: 7000})
        return true
      } 

      return false      
    },

    async onSaveFactorGroup() {
      try {
        this.resetState()
        let valid = await this.$validator.validateAll("groupFactor")
        
        if(this.doesRecordExist()) return 

        if(!valid) {
          this.toast.error('Please check to ensure that the value entered is valid.', '', {position: 'topCenter'})
          return
        }
        if(this.editedItem.factors.length == 0) {
          this.toast.error('You need to select at least one risk factor!', '', {position: 'topCenter'})
          return
        }

        let selectedFactors = this.editedItem.factors.filter(el=> el.is_selected )
        let itemToSave = Object.assign({}, this.editedItem)
        itemToSave.factors = selectedFactors
        
        if(this.editIndex > -1) {
          await this.updateRiskFactorGroup({index: this.editIndex, group: itemToSave })
          this.toast.success('Successfully updated group!', '', {position: 'topCenter'})
        } else {
          await this.createRiskFactorGroup(itemToSave)
          this.toast.success('Successfully created group!', '', {position: 'topCenter'})
        }
        this.onCloseGroupDialog()
      } catch(e) {
        console.log('creating/updating factor: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    async onDeleteFactorGroup(item) {
      this.resetState()
      this.toast.question('Are you sure you want to delete this group?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              let index = this.riskFactorState.riskFactorGroups.indexOf(item)
              await this.deleteFactorGroup(index)
              this.toast.success('Risk factor has been removed!', '', {position: 'topCenter'})
            } catch(e) {
              console.log('updating sub factor: ',e)
              this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              throw e
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]})
    },

    /** sub risk factor methods */
    onSubFactorDialogOpened(group, factor) {
      this.editedItem = group

      this.selectedFactor = factor
      if(this.selectedFactor.risk_factor_type === "Name"){
       this.headers[0].text = "Name"
      } else {
        this.headers[0].text = "Min Value"
      }

      this.originalSubFactors =  this.selectedFactor.sub_risk_factors.map((el) => ({...el}))
      this.editSubFactorDialog = true
    },

    /** Inline Edit dialog methods */
    onEditDialogOpened(item) {
      this.disableEventStyle = 'pointer-events: none'
      this.search = ''
      this.$validator.reset({scope: 'subFactor'})
      this.subFactorIndex = this.selectedFactor.sub_risk_factors.indexOf(item)
      this.selectSubRiskFactor = item
      this.previousSubFactor = Object.assign({}, item)
    },
    
    async onEditDialogClosed(item) {
      this.disableEventStyle = ''
      item = this.selectSubRiskFactor
      await this.resetEditDialog()
    },

    async onEditDialogSaved() {
      try{
        let itemToSave = Object.assign({}, this.editedItem)
        this.riskFactorState.riskFactorGroups.forEach((el, i)=> {
          if(el.ID == this.editedItem.ID) {
            this.editIndex = i
          }
        })
        
        await this.updateRiskFactorGroup({index: this.editIndex, group: itemToSave })
        this.originalSubFactors = this.selectedFactor.sub_risk_factors.map((el) => ({...el}))
        this.toast.success('Successfully updated group!', '', {position: 'topCenter'})
        this.editIndex = -1
      } catch(e) {
        console.log('creating/updating factor: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }

    },

    async resetEditDialog() {
      let isValid = await this.$validator.validateAll('subFactor')
      if(!isValid) {
        Object.keys(this.selectedFactor.sub_risk_factors[this.subFactorIndex]).forEach(key=>{
          this.selectedFactor.sub_risk_factors[this.subFactorIndex][key] = this.previousSubFactor[key]
        })
      }
      this.subFactorIndex = -1
      this.previousSubFactor = {}
      this.$validator.reset({scope: 'subFactor'})    
    },

    onSubRiskDialogClosed() {
      this.selectedFactor.sub_risk_factors = this.originalSubFactors
      this.editSubFactorDialog = false
    },

    async onDeleteSubFactor(item) {
      this.resetState()
      this.toast.question('Are you sure you want to delete this sub risk factor?', '', {
      timeout: 20000,
      close: false,
      overlay: true,  
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', async (instance, toast) => {
          try {
            let index = this.selectedFactor.sub_risk_factors.indexOf(item)
            await this.deleteSubFactor({factorIndex: this.editedIndex, index: index})
            this.toast.success('Risk factor has been removed!', '', {position: 'topCenter'})
          } catch(e) {
            console.log('updating sub factor: ',e)
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
            throw e
          }
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
        }, true],
        ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
      ]})
    }

  }


}
</script>

 
