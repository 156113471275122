<template>
  <div id="settings-layout">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<style>

</style>

<script>
import {mapMutations} from 'vuex'
export default {
  created() {
    this.init()
    this.TOGGLE_SIDEBAR_VISIBILITY(true)
  },

  data() {
    return {
      routes: [        
        { heading: 'Company Settings' },
        { title: 'Company Details', route: '/app/settings/company', icon: 'fa-file', iconSize: '12px'},
        { title: 'Branches', route: '/app/settings/branches', icon: 'business', iconSize: '12px'},
        { title: 'Departments', route: '/app/settings/departments', icon: 'fa-building', iconSize: '12px'},
        { divider: true },
        { heading: 'General Settings' },
        { title: 'Options', route: '/app/settings/account-general-settings', icon: 'fa-cog', iconSize: '12px'},
        { title: 'Account Types Setup', route: '/app/settings/account-types-settings/', icon: 'fa-credit-card', iconSize: '12px'},
        { divider: true },
        { heading: 'System Settings' },
        { title: 'Risk Factors', route: '/app/settings/factors', icon: 'fa-exclamation-triangle', iconSize: '12px'},
        { title: 'Risk-based Lending', route: '/app/settings/adjustments', icon: 'fa-sliders-h', iconSize: '12px'},
        // { title: 'Group Factors', route: '/app/settings/group-factors', icon: 'fa-layer-group', iconSize: '12px'},
        // { title: 'Geographic Definitions', route: '/app/settings/company', icon: 'fa-map-marker-alt', iconSize: '12px'},
        { title: 'Notifications', route: '/app/settings/notifications', icon: 'fa-bell', iconSize: '12px'},
        { title: 'Mass Upload', route: '/app/settings/mass-upload', icon: 'fa-upload', iconSize: '12px'},
        { title: 'Loan Templates', route: '/app/settings/loan-templates', icon: 'fa-file-pdf', iconSize: '12px'},
        { divider: true },
        { heading: 'Security Settings' },
        { title: 'Roles', route: '/app/settings/roles', icon: 'fa-key', iconSize: '12px'},
        // { title: 'Positions', route: '/app/settings/roles', icon: 'fa-key', iconSize: '12px'},
        { title: 'Users', route: '/app/settings/users', icon: 'fa-users', iconSize: '12px'},
        { divider: true },

        { heading: 'Miscellaneous Settings' },
        { title: 'ID Types', route: '/app/settings/id-types', icon: 'fa-id-card-alt', iconSize: '12px'},
        { title: 'Edit Loan Info', route: '/edit-loans', icon: 'fa-file-invoice-dollar', iconSize: '12px'},

        
      ]
    }
  },

  beforeDestroy() {
    
  },

  methods: {
    ...mapMutations([
      'SET_SIDEBAR_ROUTES',
      'TOGGLE_SIDEBAR_VISIBILITY'
    ]),
    init() {
      this.SET_SIDEBAR_ROUTES(this.routes)
    }
  },


}
</script>
