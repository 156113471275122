
export default {
    data: function () {
        return {
            doc_data: [],
            hide: false,
            expand: false,
            user : {},
            dialog_title: "",
            apps: ["All", "AppCenter"],
            selected:"All"
        }
    },
    methods: {
        // sort array
        sortArray(key, order) {
            return function innerSort(a, b) {
                if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                    return 0;
                }
                const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
                let comparison = 0;
                if (varA > varB) { comparison = 1; }
                else if (varA < varB) { comparison = -1; }
                return ((order === 'desc') ? (comparison * -1) : comparison);
            }
        },
        setDate(date){
          return this.$moment(date).format("YYYY-MM-DD hh:mm:ss A")
        },
        print() {
          this.$htmlToPaper("Print", data => {
            console.log("Printing completed or was cancelled!");
          });
        },
         checkValue(change){
          if(change == undefined || change == null){
            return false
          }else{
            return true
          }
        },
        //display object keys and values
        keysAndValues(data){
            if(data instanceof Object){
                delete data.ID;
                delete data.date_updated;
                delete data.date_created;
                let values = Object.values(data);
                let keys = Object.keys(data);
                let start = `<ul style="list-style-type:circle;">`;
                let middle = ``;
                let end = `</ul>`;
                for(var x = 0; x < keys.length ; x++){
                    if(values[x]){
                        if(Array.isArray(values[x])){
                        middle += `<li><strong style="text-transform: capitalize;"> ${keys[x].replace(/_/g, ' ')} : </strong>`;
                        middle += this.array(values[x])
                        middle += `</li>`;
                        }else if(typeof values[x] == 'object'){
                        middle += `<li><strong style="text-transform: capitalize;"> ${keys[x].replace(/_/g, ' ')} : </strong>`;
                        middle += this.object(values[x])
                        middle += `</li>`;
                        } else{
                            middle += `<li><strong style="text-transform: capitalize;"> ${keys[x].replace(/_/g, ' ')} : </strong>${values[x]}</li>`;
                        }
                    }
                }
                return start+middle+end
            }
        },
        object(data){
            delete data.ID;
            delete data.date_updated;
            delete data.date_created;
            let v = Object.values(data);
            let k = Object.keys(data);
            let s = `<ul style="list-style-type:circle;">`;
            let m = ``;
            let e = `</ul>`;
            for(var y = 0; y < k.length; y++){
                if(v[y]){
                    if(Array.isArray(v[y])){
                       m += `<li><strong style="text-transform: capitalize;"> ${k[y].replace(/_/g, ' ')} : </strong>`;
                       m += this.array(v[y])
                       m += `</li>`;
                    }else if(typeof v[y] == 'object'){
                       m += `<li><strong style="text-transform: capitalize;"> ${k[y].replace(/_/g, ' ')} : </strong>`;
                       m += this.keysAndValues(v[y])
                       m += `</li>`;
                    } else{
                        m += `<li><strong style="text-transform: capitalize;"> ${k[y].replace(/_/g, ' ')} : </strong>${v[y]}</li>`;
                    }
                }
            }
            return s+m+e;
        },
        array(data){
            delete data.ID;
            delete data.date_updated;
            delete data.date_created;
            let values = Object.values(data);
            let keys = Object.keys(data);
            let start = `<ul style="list-style-type:circle;">`;
            let middle = ``;
            let end = `</ul>`;
            for(var x = 0; x < keys.length ; x++){
                if(values[x]){
                    if(typeof values[x] == 'object'){
                       middle += `<li><strong style="text-transform: capitalize;"> ${keys[x].replace(/_/g, ' ')} :  </strong>`;
                       middle += this.object(values[x])
                       middle += `</li>`;
                    }else{
                        middle += `<li>${values[x]}</li>`;
                    }
                }
            }
            return start+middle+end
        }
    }   
}