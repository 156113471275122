<template>
  <div>
    <v-card class="pa-2 mb-3">
      <v-card-title class="title font-weight-bold">
        <span>Debt Service Ratio (DSR) Waiver Policy Settings</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reset()">
          <v-icon left>add</v-icon>Add Level
        </v-btn>
      </v-card-title>
    </v-card>

    <v-layout wrap row>
      <v-flex md12 sm12>
        <!-- display if there is no data -->
        <v-card v-if="items.length == 0">
          <v-flex xs12 pt-5 class="text-xs-center">
            <v-icon color="primary" size="70">fa-folder-open</v-icon>
          </v-flex>
          <v-flex xs12 pt-2 pb-4>
            <h2 class="text-xs-center">Nothing Here</h2>
          </v-flex>
        </v-card>

        <!-- display if data is able -->
        <v-card class="pa-2 mb-3" v-else>
          <v-data-table
            :headers="header"
            :items="items"
            :loading="waiverSettingsStore.loading"
            class="v-table"
            item-key="ID"
          >
            <template v-slot:items="props">
              <td>
                <v-switch
                  v-model="props.item.waiver_active"
                  @change="activateLevel(props.item.ID, $event)"
                  :label="props.item.waiver_active == false ? 'Off' : 'On'"
                ></v-switch>
              </td>
              <td>{{ props.item.waiver_level }}</td>
              <td>{{ (props.item.motor_vehicle * 100).toFixed(2) + "%" }}</td>
              <td>{{ (props.item.unsecured * 100).toFixed(2) + "%" }}</td>
              <td>{{ (props.item.cash_secured * 100).toFixed(2) + "%" }}</td>
              <td>{{ (props.item.real_estate * 100).toFixed(2) + "%" }}</td>
              <td>{{ (props.item.chattels * 100).toFixed(2) + "%" }}</td>
              <td>
                <v-icon @click="setEdit(props.item)">edit</v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- form dialog -->
    <v-dialog v-model="form_dialog" width="85%" persistent scrollable>
      <v-card>
        <v-card-title class="primary layout justify-center">
          <span class="headline white--text">Enter Level Details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 class="pa-1">
                  <v-text-field
                    prepend-icon="edit"
                    v-model="form_data.waiver_level"
                    label="Waiver Level*"
                    :disabled="true"
                    v-if="form_data.waiver_level"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="edit"
                    v-model="level"
                    label="Waiver Level*"
                    :disabled="true"
                    v-else
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    v-model="form_data.motor_vehicle"
                    label="Motor Vehicle Loan Percentage (%)*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    v-model="form_data.unsecured"
                    label="Unsecured Loan Percentage (%)*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    v-model="form_data.cash_secured"
                    label="Cash Secured Loan Percentage (%)*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    v-model="form_data.real_estate"
                    label="Real Estate Loan Percentage (%)*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    v-model="form_data.chattels"
                    label="Chattels Loan Percentage (%)*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>*all fields are required</small>
          </v-form>
        </v-card-text>

        <v-card-actions class="headline grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="close()">
            Close <v-icon dark left>remove_circle</v-icon></v-btn
          >
          <v-btn
            color="blue darken-1"
            flat
            @click="validateForm()"
            :disabled="!valid"
          >
            Submit <v-icon dark right>check_circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "DSRWaiverPolicySettings",
  data() {
    return {
      form_data: {},
      moneyRules: [
        (v) => !!v || "Amount Required",
        (v) =>
          (v && parseInt(v) <= 100 && parseInt(v) >= 0) ||
          "Amount must be less than 100 and greater than 0",
      ],
      header: [
        {
          text: "Off / On",
          value: "waiver_active",
          align: "left",
          sortable: false,
        },
        {
          text: "Waiver Level",
          value: "waiver_level",
          align: "left",
          sortable: false,
        },
        {
          text: "Motor Vehicle",
          value: "motor_vehicle",
          align: "left",
          sortable: false,
        },
        {
          text: "Unsecured",
          value: "unsecured",
          align: "left",
          sortable: false,
        },
        {
          text: "Cash Secured",
          value: "cash_secured",
          align: "left",
          sortable: false,
        },
        {
          text: "Real Estate",
          value: "real_estate",
          align: "left",
          sortable: false,
        },
        {
          text: "Chattels",
          value: "chattels",
          align: "left",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false,
        },
      ],
      form_dialog: false,

      items: [],
      valid: false,
      refresh: false,
    };
  },

  methods: {
    ...mapGetters(["getAllDSRLevels"]),
    ...mapActions([
      "addDSRWaiver",
      "editDSRWaiver",
      "getDSRWaiver",
      "setDSRWaiverStatus",
    ]),
    reset() {
      this.$refs.form.resetValidation();
      this.errors.clear();
      this.form_data = {};
      this.form_data.ID = "";
      this.level = this.items.length + 1;
      this.form_data.waiver_active = false;
      this.form_dialog = true;
    },
    close() {
      this.errors.clear();
      this.form_dialog = false;
    },
    rese() {
      this.$refs.form.reset();
      this.errors.clear();
    },
    setEdit(data) {
      this.level = data.waiver_level;
      this.form_data = {
        ID: data.ID,
        waiver_level: data.waiver_level,
        motor_vehicle: (data.motor_vehicle * 100).toFixed(2),
        chattels: (data.chattels * 100).toFixed(2),
        unsecured: (data.unsecured * 100).toFixed(2),
        real_estate: (data.real_estate * 100).toFixed(2),
        cash_secured: (data.cash_secured * 100).toFixed(2),
      };
      this.form_dialog = true;
    },
    async validateForm() {
      let data = {
        ID: this.form_data.ID,
        waiver_level: this.form_data.waiver_level
          ? this.form_data.waiver_level
          : this.level,
        waiver_active: this.form_data.waiver_active,
        motor_vehicle: (this.form_data.motor_vehicle / 100).toFixed(2),
        chattels: (this.form_data.chattels / 100).toFixed(2),
        unsecured: (this.form_data.unsecured / 100).toFixed(2),
        real_estate: (this.form_data.real_estate / 100).toFixed(2),
        cash_secured: (this.form_data.cash_secured / 100).toFixed(2),
      };

      if (this.$refs.form.validate()) {
        if (this.form_data.ID == "") {
          this.addData(data);
        } else {
          this.editData(data);
        }
      } else {
        this.$toast.error("Please complete the form with valid data.", "", {
          position: "topCenter",
        });
      }
    },

    async addData(obj) {
      try {
        obj.waiver_level = this.items.length + 1;

        const result = await this.addDSRWaiver(obj);
        if (result) {
          this.getData();

          this.$toast.success("Waiver Level Added Successfully", "", {
            position: "topCenter",
          });
          this.reset();
        } else {
          this.$toast.error("Operation Failed", "", {
            position: "topCenter",
          });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },
    async editData(obj) {
      try {
        const result = await this.editDSRWaiver(obj);
        if (result) {
          this.getData();
          this.$toast.success("Waiver Level Updated Successfully", "", {
            position: "topCenter",
          });
          this.close();
        } else {
          this.$toast.error("Operation Failed", "", { position: "topCenter" });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },
    async getData() {
      try {
        const result = await this.getDSRWaiver();
        if (result) {
          this.items = this.waiverSettingsStore.dsr;
        } else {
          this.items = [];
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },
    async activateLevel(id, value) {
      let data = {
        ID: id,
        value: value,
      };
      try {
        const result = await this.setDSRWaiverStatus(data);
        if (result) {
          if (value == false) {
            this.$toast.success("Waiver Level Deactivated", "", {
              position: "topCenter",
            });
          } else {
            this.$toast.success("Waiver Level Activated", "", {
              position: "topCenter",
            });
          }
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },
  },
  computed: {
    level: {
      get: function() {
        return this.items.length + 1;
      },
      set: function(newVal) {
        console.log(newVal);
      },
    },

    ...mapState(["waiverSettingsStore"]),
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.toggle-switch {
  width: 100px;
  height: 37px !important;
  margin: 0px !important;
  border-radius: 5px;
  padding-left: 10px !important;
  background: #f7f7f7 !important;
  border: 2px solid #e3e3e3;
}

.switches {
  /* width:40px !important; 
        margin: 0px auto; */
  padding-top: 15px;
  padding-bottom: -10px;
}
</style>
