<template>
  <v-container fluid>
    <v-card flat>
      <h2 class="pa-2">Loan Application Templates</h2>

      <v-card-text>
        <v-layout row wrap>
          <v-flex v-if="items.length == 0">
            <div class="text-xs-center" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); padding-top: 300px;">
              <v-icon color="error" large>error</v-icon>
              <h3>No Loan Templates Found.</h3></div>
          </v-flex>
          <v-flex v-else class="pb-4" v-for="(item, index) in items" :key="index">
            <v-card class="mt-3" height="250" width="250">
              <v-sheet class="v-sheet--offset stats ml-3" color="primary" elevation="12">
                <v-icon large color="white">insert_drive_file</v-icon>
              </v-sheet>
              <v-card-text class="pt-0">
                <div class="title font-weight-light mb-2">{{ item.title }}</div>
                <v-divider class="my-2"></v-divider>
                <span class="caption grey--text font-weight-bold">{{ item.details }}</span>
                <v-icon
                  class="mr-2"
                  medium
                  color="primary"
                  style="right: 0; bottom: 3px; position: absolute"
                  title="Click to view template"
                  @click="getComponent(item.component,item)"
                >info</v-icon>
                <v-icon
                  v-if="template_type.length==1 && item.title =='Loan Application Form' "
                  class="mr-2"
                  medium
                  color="primary"
                  style="right: 0; bottom: 3px; position: absolute; margin: 200px"
                  title="Click to edit template type"
                  @click="edit()"
                >edit</v-icon>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <!-- FULLSCREEN MODAL -->
    <v-dialog fullscreen hide-overlay persistent v-model="fullScreenadditionalsDialog">
      <v-card>
        <v-toolbar dense dark color="primary mb-1">
          <v-btn icon dark @click="closeAdditionsDialog">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{fullScreenDialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark depressed color="primary" @click="printPDF">Print</v-btn>
          <v-btn dark depressed color="primary" @click="closeAdditionsDialog">Exit</v-btn>
        </v-toolbar>
        <v-container class="pa-0" fluid>
          <component
            ref="largeDialogComponent"
            :opened="fullScreenadditionalsDialog"
            :is="fullScreenAdditonActiveComponent"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <!-- SELECT LOAN APPLICATION TYPE MODAL -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Select Loan Application Type</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="dialog = false">Exit</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-for="type in selected" :key="type.template">
          <v-checkbox
            v-model="template"
            :label="type.loan_category"
            :value="type.template"
            class="ml-5"
          ></v-checkbox>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="loader = 'loading', save()"
            v-if="selected.length != 0"
          >Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TemplateService from "@/services/loan-template-service";
import LoanApplicationForm from "@/views/settings/loan-templates/loan-templates-components/LoanApplicationForm";
import CreditDisclosureAgreement from "@/views/settings/loan-templates/loan-templates-components/CreditDisclosureAgreement";
import CommunityCareAddOnDisclosure from "@/views/settings/loan-templates/loan-templates-community-care-cu/AddOnLoanDisclosure";
import CommunityCareLoanDisclosure from "@/views/settings/loan-templates/loan-templates-community-care-cu/LoanDisclosure";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import storageService from "@/services/storage-service";

export default {
  name: "LoanTemplate",
  data() {
    return {
      template_type: [],
      template: [],
      dialog: false,
      ID: "",
      value: 0,
      margin: 0,
      interval: {},
      selected: [
        {
          template: "template_1",
          loan_category: "Cash Secured",
        },
        {
          template: "template_2",
          loan_category: "Real Estate",
        },
        {
          template: "template_3",
          loan_category: "Motor Vehicle",
        },
        {
          template: "template_4",
          loan_category: "Unsecured",
        },
      ],
      created_by: "",
      component_name: "",
      disabled: false,
      fullScreenadditionalsDialog: false,
      fullScreenAdditonActiveComponent: null,
      fullScreenDialogTitle: "",

      company_logo: "",
      company_name: "",

      //TODO: read company names from env instead of local storage
      items: [
        {
          title: "Loan Application Form",
          margin: [10, 20, 10, 20],
          details: "view loan application form details",
          component: LoanApplicationForm,
          company_name: "Technosoft Programmers Credit Cooperative Credit Union",
        },
        {
          title: "Credit Information Disclosure Agreement",
          margin: [10, 20, 10, 20],
          details: "view agreement details for credit information disclosure",
          component: CreditDisclosureAgreement,
          company_name: "Technosoft Programmers Credit Cooperative Credit Union"
        },
        {
          title: "Add On Loan Disclosure Agreement",
          margin: [10, 10, 10, 10],
          details: "view loan disclosure statement and add on agreement",
          component: CommunityCareAddOnDisclosure,
          company_name: "Technosoft Programmers Credit Cooperative Credit Union"
        },
        {
          title: "Loan Disclosure Agreement",
          margin: [10, 10, 10, 10],
          details: "view agreement details for loan disclosure",
          component: CommunityCareLoanDisclosure,
          company_name: "Technosoft Programmers Credit Cooperative Credit Union"
        },
      ],
    };
  },

  methods: {
    save() {
      this.dialog = false;

      const data = {
        ID: this.ID,
        template: this.template,
        created_by: this.created_by,
      };

      let user = storageService.getItem("user");
      if (user != null || user != undefined) {
        this.created_by = user.person_id;
        this.company_logo = user.company.logo;
      }

      if (this.ID == "") {
        this.addTemp(data);
        this.disabled = false;
      } else if (this.ID != "") {
        this.editTemp(data);
        this.disabled = false;
      }
    },
    getCurrentUserDetails(){
      var localUser = storageService.getItem('user');
      if(localUser){
          this.created_by = localUser['ID'];
      }
    },
    close() {
      this.dialog = false;
    },
    closeAdditionsDialog() {
      this.fullScreenadditionalsDialog = false;
    },
    getComponent(value, data) {
      this.component_name = data.title;
      this.margin = data.margin;
      if (
        this.component_name == "Loan Application Form" &&
        this.template_type.length == 0
      ) {
        this.dialog = true;
      } else {
        this.showFullAdditionComponent(value, data.title);
      }
      return data;
    },
    edit() {
      let d = this.template_type[0].tempType;

      if (typeof d.template != "object") {
        var f = this.template_type[0].tempType.template.split(",");
        this.template = f;
      } else {
        this.template = d.template;
      }

      this.ID = d.ID;
      this.created_by = d.created_by;
      this.dialog = true;
    },
    showFullAdditionComponent(component, title) {
      this.fullScreenAdditonActiveComponent = component;
      this.fullScreenadditionalsDialog = true;
      this.fullScreenDialogTitle = title;
    },

    async addTemp(item) {
      this.disabled = true;
      try {
        this.loading = true;
        const result = await TemplateService.addTemplate(item);
        if (result.status == 0) {
          this.getTemp();
          this.$toast.success(result.message, "", {
            position: "topCenter",
          });
        } else {
          this.$toast.error(result.message, "", {
            position: "topCenter",
          });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", {
            position: "topCenter",
          });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            {
              position: "topCenter",
            }
          );
        }
      }
    },

    async getTemp() {
      try {
        this.loading = true;
        let result = await TemplateService.getTemplate();
        if (result.length > 0) {
          this.template_type = result;
        } else {
          this.template_type = [];
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", {
            position: "topCenter",
          });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            {
              position: "topCenter",
            }
          );
        }
      }
    },

    async editTemp(item) {
      this.disabled = true;
      try {
        this.loading = true;
        const result = await TemplateService.editTemplate(item);
        if (result.status == 0) {
          this.getTemp();
          this.$toast.success(result.message, "", {
            position: "topCenter",
          });
        } else {
          this.$toast.error(result.message, "", {
            position: "topCenter",
          });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", {
            position: "topCenter",
          });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            {
              position: "topCenter",
            }
          );
        }
      }
    },

    printPDF() {
      var printContents = document.getElementById("body").innerHTML;

      var doc = new jsPDF();
      doc.orientation = 'p';
      doc.unit = 'mm';
      doc.format = 'letter';
      doc.putOnlyUsedFonts = true;

      var opt = {
        margin: this.margin,
        filename: this.component_name + ".pdf",
        image: { type: "jpeg", quality: 0.95 },
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: doc,        
      };
      html2pdf().set({
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      });
      html2pdf()
        .from(printContents)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          pdf.autoPrint();
          window.open(pdf.output("bloburl"), "_blank");
        });
    },
  },

  mounted() {
    this.getCurrentUserDetails();
    this.getTemp();
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
    
     let user = JSON.parse(localStorage.getItem("user"));
      if (user != null || user != undefined) {
        this.created_by = user.person_id;
        this.company_logo = user.company.logo;
        this.company_name = user.company.company_name;
      }

      this.items = this.items.filter(el => el.company_name == this.company_name);    
  },
};
</script>

<style scoped>
.v-sheet--offset.stats {
  width: 80px;
  height: 80px;
  top: -24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>