import httpClient from '@/common/http'
export default {
  create(obj) {
    return httpClient.request('post', 'settings/loan_origination/loan_checklist_items', obj);
  },

  update(id, obj) {
    return httpClient.request('put', `settings/loan_origination/loan_checklist_items/${id}`, obj);
  },

  delete(id) {
    return httpClient.request('delete', `settings/loan_origination/loan_checklist_items/${id}`);
  },

  findOne(id) {
    return httpClient.request('get', `settings/loan_origination/loan_checklist_items/${id}`)
  },

  findAll(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
    return httpClient.request('get', `settings/loan_origination/loan_checklist_items?${filter}${query}`)
  }

}