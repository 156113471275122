import httpClient from '@/common/http'
export default {
  async create(obj) {
    return await httpClient.request('post', 'settings/loan_origination/yapu', obj);
  },

  async update(obj) {
    return await httpClient.request('put', `settings/loan_origination/yapu`, obj);
  },

  async findAll() {
    return await httpClient.request('get', `settings/loan_origination/yapu`);
  },

  async delete(id) {
    return await httpClient.request('delete', `settings/loan_origination/yapu/${id}`);
  },
}