<template>
  <div id="public-layout">
    <v-app>
       
      <v-content>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>
