<template>
</template>
<style scope>
</style>
<script>
import AuthService from "@/services/authentication-service";

export default {
  data() {
    return {};
  },

  created() {
    AuthService.logout();
  },

};
</script>
