<template>
    <div>

        <!-- Instructions Dialog -->
        <template>
            <div class="text-xs-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-title class="title" primary-title>
                            <v-icon class="mr-2">list</v-icon> {{ selectedItem.title }}: Instructions
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text v-html="selectedItem.instructions">
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" flat @click="dialog = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>

        <!-- Processing Dialog -->
        <!-- <template>
            <div class="text-xs-center">
                <v-dialog v-model="LoaderDialog" persistent width="500">
                    <v-card>
                        <v-dialog v-model="loader" width="120">
                            <v-card color="primary" dark>
                                <v-card-text class="text-xs-center">
                                    Processing
                                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-card-title class="title primary white--text" primary-title>
                            <v-icon class="white--text mr-2">circle-slice-3</v-icon> {{ dialogInfo.title }}
                        </v-card-title>
                        <v-card-text v-html="dialogInfo.message" v-if="dialogInfo.message" :class="`dialogInfo.message ${ error ? 'is-danger' : 'is-success' }`">
                            {{ dialogInfo.message }}
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions v-show="showButton">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" flat @click="LoaderDialog = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template> -->

        <!-- Upload Options -->
        <v-card>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-side-icon></v-toolbar-side-icon>
                <v-toolbar-title>Mass Upload</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="ux.processing">
                <div class="mx-3">
                    <p class="title blinking mt-3 text-md-center"><span class="text-capitalize">{{ ux.option }}</span>:
                        {{ ux.progress }} </p>
                    <v-progress-linear :indeterminate="true"></v-progress-linear>
                </div>
            </v-card-text>

            <v-card-text v-if="ux.success" class="text-md-center mt-4">
                <div class="mx-3 d-inline-flex">
                    <v-icon x-large color="green">fas fa-check-circle</v-icon>
                    <p class="display-1 ml-2">Successfully Uploaded</p>
                </div>
                <p class="title">Mass Upload successful. Processing results below:</p>
                <div class="text-xs-center mx-auto">
                    Total Persons: <b>{{ ux.totalPersons }}</b> <br>
                    Total Loans: <b>{{ asMoney(ux.totalLoans) }}</b> <br>
                    Total Cash Collaterals: <b>{{ asMoney(ux.totalCC) }}</b> <br>
                    Total Motor Vehicle Collaterals: <b>{{ asMoney(ux.totalMVC) }}</b> <br>
                    Total Real Estate Collaterals: <b>{{ asMoney(ux.totalREC) }}</b> <br>
                    Total Sub-Accounts: <b>{{ ux.totalSubaccounts }}</b> <br>
                    Total Transactions: <b>{{ asMoney(ux.totalTransactions) }}</b> <br>
                </div>
            </v-card-text>

            <v-card-text v-if="ux.error" class="text-md-center mt-4">
                <div class="mx-3 d-inline-flex">
                    <v-icon x-large color="red">fas fa-exclamation-circle</v-icon>
                    <p class="display-1 ml-2">Server Error</p>
                </div>
                <p class="title">Failed to <b>process file</b> on server. Try checking the file requirements and try
                    again.</p>
                <div class="text-md-center">
                    <li>Ensure the file provided for upload is of CSV file format.</li>
                    <li>Column headers should be placed at ROW 1 - the top of the file.</li>
                    <li>All dates in file should follow the YYYY-MM-DD date format for processing.</li>
                </div>
            </v-card-text>
            <hr class="mt-4">
            <v-card-text>
                <v-list two-line subheader>
                    <template v-for="(item, index) in uploadMethods">
                        <v-list-tile :key="item.title" avatar>

                            <v-list-tile-action>
                                <v-list-tile-action-text>
                                    <input class="inputfile" type="file" id="file" ref="file"
                                        v-on:change="handleFileUpload(index)" />
                                </v-list-tile-action-text>
                            </v-list-tile-action>

                            <v-list-tile-content>
                                <v-list-tile-title>{{item.title}}</v-list-tile-title>
                                <v-list-tile-sub-title>{{item.subtitle}}<v-icon @click="showInstructions(item)"
                                        class="ml-3">info</v-icon>
                                </v-list-tile-sub-title>
                            </v-list-tile-content>

                            <v-btn v-show="item.show" color="green" class="white--text" v-on:click="validateFile(item)"
                                depressed>
                                <v-icon left>cloud_upload</v-icon>
                                Start Upload
                            </v-btn>

                        </v-list-tile>
                        <v-divider v-if="index + 1 < uploadMethods.length" :key="index"></v-divider>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>

    </div>
</template>

<style scoped>
    .inputfile {
        font-size: 16px;
    }

    /* }
    h1, h2, h3 {
        color: #505a6b;
    }
    .v-progress-circular {
        margin: 1rem
    } */
</style>

<script scoped>
    import MassUploadService from "@/services/mass-upload-service";
    import {
        mapMutations
    } from "vuex";

    export default {
        data() {
            return {

                ux: {
                    processing: false,
                    success: false,
                    error: false,
                    option: '',
                    progress: 'Uploading & Processing Data...',

                    totalPersons: 0,
                    totalLoans: 0,
                    totalCC: 0,
                    totalMVC: 0,
                    totalREC: 0,
                    totalSubaccounts: 0,
                    totalTransactions: 0,
                },

                file: '',
                totalPersons: 0,
                totalLoans: 0,
                LoaderDialog: false,
                showButton: false,
                dialogInfo: {
                    title: '',
                    message: ''
                },
                loader: false,
                error: false,
                instructions: '',
                selectedItem: '',
                dialog: false,

                uploadMethods: [{
                        title: 'Mass Update',
                        subtitle: 'Creates or Updates : Persons, Accounts, Loans.',
                        option: "mass-update",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>Loan_Ref_No.</li><li>TRN</li>",
                        show: false
                    },
                    {
                        title: 'Person Upload',
                        subtitle: 'Creates or Updates : Persons ( Demography ) and Accounts.',
                        option: "person-upload",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>TRN</li>",
                        show: false
                    },
                    // {
                    //     title: 'Loan Upload/Update',
                    //     subtitle: 'Creates or Updates : Loans.',
                    //     option: "loan-upload",
                    //     instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>Loan_Ref_No.</li>",
                    //     show: false
                    // },
                    {
                        title: 'Loan Collaterals',
                        subtitle: 'Creates or Updates : Loan Collaterals.',
                        option: "collateral-upload",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li><li>Loan_Ref_No.</li><li>Cash_Collateral</li><li>Motor_Vehicle_Collateral</li><li>Real_Estate_Collateral</li>",
                        show: false
                    },
                    {
                        title: 'Sub-Accounts Upload',
                        subtitle: 'Creates or Updates : Sub-Accounts.',
                        option: "subaccount-upload",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li>",
                        show: false
                    },
                    {
                        title: 'Transactions Upload',
                        subtitle: 'Creates or Updates : Transactions.',
                        option: "transactions-upload",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li>",
                        show: false
                    }
                ]
            };
        },

        created() {},

        beforeDestroy() {},

        methods: {
            ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

            handleFileUpload(val) {
                let index = val
                var i = -1
                //gets selected file object
                this.file = this.$refs.file[index].files;
                // console.log(this.file)

                //sets upload button by selected option
                for (i = 0; i < this.uploadMethods.length; i++) {
                    if (i == index) {
                        this.uploadMethods[i].show = true
                    } else {
                        this.uploadMethods[i].show = false
                        //reset previous selected file field value
                        this.$refs.file[i].value = ''
                    }
                }

                this.dialogInfo.message = ""
                this.dialogInfo.title = ""
                this.error = false
            },

            showInstructions(item) {
                this.dialog = true;
                this.selectedItem = item;
            },

            validateFile(item) {
                let uploadOption = item.option
                this.ux.option = uploadOption

                this.ux.processing = true
                this.ux.success = false
                this.ux.error = false

                switch (uploadOption) {
                    case "subaccount-upload":
                    case "transactions-upload":
                        this.transactionUpload(uploadOption)
                        break;
                    case "collateral-upload":
                    case "person-upload":
                    case "mass-update":
                        this.massUpload(uploadOption)
                        break;
                }

            },

            async massUpload(option) {
                console.log('massUpload: ');
                this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
                // this.isLoading = true
                let opt = option
                let formData = new FormData();
                let newFile = this.file[0]
                formData.append('file', newFile);

                try {
                    let response = await MassUploadService.massUpload(opt, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    // this.dialogInfo.message = "File was successfully uploaded. Initializing database insertion......."

                    this.ux.progress = 'Processing file data...'
                    this.ux.success = true

                    this.error = false
                    this.file = ""
                    // this.totalPersons = response.totalPersons
                    // this.totalLoans = response.totalLoans

                    this.ux.totalPersons = response.totalPersons;
                    this.ux.totalLoans = response.totalLoans;
                    this.ux.totalCC = response.totalCashCollaterals;
                    this.ux.totalMVC = response.totalMvCollaterals;
                    this.ux.totalREC = response.totalReCollaterals;
                    console.log(response);

                } catch (e) {
                    console.log('fetching data: ', e)
                    // this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                    this.dialogInfo.message = 'Error in Processing File'
                    this.error = true
                    this.ux.processing = false
                    this.ux.success = false
                    this.ux.error = true
                } finally {
                    this.ux.processing = false
                    // this.ux.success = true
                    this.showButton = true
                    this.dialogInfo.message = '<v-subheader> Successfully Uploaded </v-subheader> <li><b>' + ' ' +
                        this.totalPersons + ' ' + '</b> Persons with Accounts </li> <li><b>' + ' ' + this
                        .totalLoans + ' ' + '</b>Loans in Total </li>'
                }
            },

            async transactionUpload(option) {
                console.log('transactionUpload: ');
                this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
                let opt = option
                let formData = new FormData();
                let newFile = this.file[0]
                formData.append('file', newFile);

                try {
                    let response = await MassUploadService.transactionUpload(opt, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    this.ux.progress = 'Processing file data...'
                    this.ux.success = true

                    this.error = false
                    this.file = ""

                    this.ux.totalPersons = 0;
                    this.ux.totalLoans = 0;
                    this.ux.totalCC = 0;
                    this.ux.totalMVC = 0;
                    this.ux.totalREC = 0;
                    this.ux.totalSubaccounts = response.totalAccounts;
                    this.ux.totalTransactions = response.totalTransactions;
                    console.log(response);

                } catch (e) {
                    console.log('fetching data: ', e)
                    // this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                    this.dialogInfo.message = 'Error in Processing File'
                    this.error = true
                    this.ux.processing = false
                    this.ux.success = false
                    this.ux.error = true
                } finally {
                    this.ux.processing = false
                    // this.ux.success = true
                    this.showButton = true
                    this.dialogInfo.message = '<v-subheader> Successfully Uploaded </v-subheader> <li><b>' + ' ' +
                        this.totalSubaccounts + ' ' + '</b> Sub-Accounts </li> <li><b>' + ' ' + this
                        .totalTransactions + ' ' + '</b>Transactions in Total </li>'
                }
            },
        }
    };
</script>