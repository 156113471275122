import httpClient from '@/common/http'
export default {
  createAccountType(obj) {
    return httpClient.request('post', 'settings/account/account_types', obj);
  },

  updateAccountType(id, obj) {
    return httpClient.request('put', `settings/account/account_types/${id}`, obj);
  },

  deleteAccountType(id, replacement) {
    return httpClient.request('delete', `settings/account/account_types/${id}/replacement/${replacement}`);
  },

  createSubAccountType(accountTypeID, obj) {
    return httpClient.request('post', `settings/account/account_types/${accountTypeID}/sub_accounts`, obj);
  },

  updateSubAccountType(accountTypeID, id, obj) {
    return httpClient.request('put', `settings/account/account_types/${accountTypeID}/sub_accounts/${id}`, obj);
  },

  deleteSubAccountType(id, replacement) {
    return httpClient.request('delete', `settings/account/sub_accounts/${id}/replacement/${replacement}`);
  },

  createDefinitionType(subTypeID, obj) {
    return httpClient.request('post', `settings/account/sub_accounts/${subTypeID}/account_definitions/`, obj);
  },

  updateDefinitionType(subTypeID, id, obj) {
    return httpClient.request('put', `settings/account/sub_accounts/${subTypeID}/account_definitions/${id}`, obj);
  },


  deleteAccountDefinition(id, replacement) {
    return httpClient.request('delete', `settings/account/account_definitions/${id}/replacement/${replacement}`);
  },

  findAll() {
    return httpClient.request('get', `/settings/account/account_types`)
  }

}