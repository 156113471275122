import { render, staticRenderFns } from "./Loan-General-Settings.vue?vue&type=template&id=785d9ac1&scoped=true"
import script from "./Loan-General-Settings.vue?vue&type=script&lang=js"
export * from "./Loan-General-Settings.vue?vue&type=script&lang=js"
import style0 from "./Loan-General-Settings.vue?vue&type=style&index=0&id=785d9ac1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785d9ac1",
  null
  
)

export default component.exports