<template>
  <div>   
    <v-dialog v-model="batchInsertDialog" fullscreen persistent>
      <v-card>
       <v-progress-linear height="3" class="ma-0" value="100"></v-progress-linear>
        <v-card-title class="mb-0 pb-0 pt-4">
          <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          <h1>
          Risk Factor Batch Data Load
          </h1>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-alert dismissible :value="alertController.show" :type="alertController.type" class="main-alert">
            {{alertController.message}}
          </v-alert>
          <v-dialog v-model="messageBox.visible" max-width="390" persistent>
            <v-card>
              <v-card-title class="headline">
                <!-- <v-icon :color="messageBox.color" class="mr-2" large>{{messageBox.icon}}</v-icon> -->
                {{messageBox.title}}
              </v-card-title>
              <v-card-text class="mt-0 pt-0 pb-0">
                <span v-html="messageBox.message"></span>
              </v-card-text>
              <v-card-actions class="mt-0 pt-2">
                  <v-spacer></v-spacer>
                  <v-btn v-if="messageBox.noFunc" flat @click="messageBox.noFunc">
                    {{messageBox.denyText}}
                  </v-btn>
                  <v-btn :loading="messageBox.btnLoading" :color="messageBox.color" flat @click="messageBox.yesFunc">
                    {{messageBox.confirmText}}
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <h3>Insert or updates risk factor details from excel file. Click the button below to choose a file.</h3>
          <input class="mt-2 mb-2" type="file" @click="onFileClicked" @change="onBatchFileUploadChange" />

          <v-layout>
            <v-flex>
              <v-btn color="primary darken-1" class="ml-0" :href="templateUrl" download="Batch_User_Template.xlsx" dark>
                <v-icon class="mr-2">cloud_download</v-icon>
                Download Template
              </v-btn>
              <v-btn  :loading="isLoading" color="primary" :disabled="isLoading || batchData.length == 0" @click="submit">
                <v-icon class="mr-2">cloud_upload</v-icon>
                Upload
              </v-btn>
            </v-flex>
            <v-flex xs6 sm4>
              <v-text-field 
              label="Search" 
              v-model="searchTableModel"
              prepend-icon="search" :width="500" />
            </v-flex>
          </v-layout>
          
          <xlsx-read :file="batchFile">
            <xlsx-json :sheet="selectedSheet" @parsed="onBatchDataParsed">
              <template>
                <div>
                <v-data-table 
                  class="elevation-0" 
                  :headers="batchInsertHeaders" 
                  :search="searchTableModel"
                  :loading="tableLoading"
                  :items="batchData || []">
                  <template slot="items" slot-scope="props" >
                    <tr :title="props.item.message" :class="{batchNotValid: !props.item.is_valid}" >
                      <td>{{props.item.Name}}</td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)" large lazy :return-value.sync="props.item.MaxRiskFactorScore" > 
                          {{ props.item.MaxRiskFactorScore }}
                          <v-text-field
                            slot="input"
                            v-validate="'required|numeric|min_value:0|max_value:1000'" 
                            data-vv-scope="batch"
                            data-vv-name="score"
                            :error-messages="errors.collect('batch.score')"
                            v-model="props.item.MaxRiskFactorScore"  
                            label="Score" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)" large lazy :return-value.sync="props.item.DefaultScore" > 
                          {{ props.item.DefaultScore  }}
                          <v-text-field
                            slot="input"
                            v-validate="'required|numeric|min_value:0|max_value:1000'" 
                            data-vv-scope="batch"
                            data-vv-name="default"
                            data-vv-as="default score"
                            :error-messages="errors.collect('batch.default')"
                            v-model="props.item.DefaultScore"  
                            label="Default Score" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-checkbox v-model=" props.item.RiskFactorScoringOn" primary hide-details ></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox v-model=" props.item.RiskBasedLendingOn" primary hide-details ></v-checkbox>
                      </td>
                     
                    </tr>
                    
                  </template>
                  <template slot="no-data">
                    
                  </template>
                </v-data-table>
                </div>
              </template>
            </xlsx-json>
          </xlsx-read> 
        </v-card-text>
        
      </v-card>
    </v-dialog> 
  </div>
</template>

<style type="css">
h1, h2, h3 {
color: #505a6b;}

.batchNotValid {
  background: #fdc4ac !important;}

.main-alert {
  z-index: 99999;
  margin:0;
  padding-top: 5px; 
  padding-bottom: 5px; 
  padding-left: 10px;
  padding-right: 10px;
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0;}
</style>

<script>
import RiskFactorService from '@/services/risk-factor-service'
import {XlsxRead, XlsxJson} from "vue-xlsx";

export default {
  components: {
    XlsxRead,
    XlsxJson
  },

  created () {
  },

  data() {
    return {
      validator: null,

      templateUrl: `${process.env.VUE_APP_ROOT_API.replace('/api/v1', '')}public/assets/templates/risk_factor_template.xlsx`,
      batchInsertDialog: false,
      searchTableModel: '',
      isLoading: false,
      tableLoading: false,
      selectedSheet: 'Factors',
      batchData: [],
      batchFile: null,
      batchFileEL: null,
      batchInsertDialog: false,
      batchInsertHeaders: [
        { text: 'Name', align: 'left', sortable: false, value: 'Name'},
        { text: 'Risk Factor Score', align: 'left', sortable: false, value: 'RiskFactorScor'},
        { text: 'Default Score', align: 'left', sortable: false, value: 'DefaultScore'},
        { text: 'Risk Factor Scoring On', align: 'left', sortable: false, value: 'RiskFactorScoringOn'},
        { text: 'Risk Based Lending On', align: 'left', sortable: false, value: 'RiskBasedLendingOn'},
      ],

      messageBox: {
        visible: false,
        icon: '',
        color: '',
        title: '',
        message: '',
        confirmText: 'Ok',
        denyText: 'No',
        yesFunc: function() {},
        noFunc: function() {}
      },
      alertController: {
        show: false,
        type: 'error',
        message: 'Something went wrong loading page. Please refresh the page and try again.'
      },
    }
  },

  computed: {},

  methods: {
    open() {
      this.batchInsertDialog = true;
      this.batchData = [];
      this.batchFile = null;
    },

    close() {
      this.batchInsertDialog = false;
      this.batchData = [];
      this.batchFile = null;
      if(this.batchFileEL)
        this.batchFileEL.value = "";
    },

    onFileClicked() {
      if(this.batchFileEL) {
        this.batchData = [];
        this.batchFileEL.value = "";
      }
    },

    async onBatchDataParsed(records) {
      if(records.length > 100) {
        this.messageBox = {
          visible: true,
          icon: 'warning',
          color: 'warning',
          title: 'Batch Limit Exceeded',
          message: '<b class="mt-2">Cannot exceed 100 records<b>',
          confirmText: 'Ok',
          denyText: 'No',
          yesFunc: () => {
            this.messageBox.visible = false;
          },
          noFunc: null
        }
        this.tableLoading = false;
        return;
      }

      records = records.map((r, i) => {
        r.index = i;
        return r;
      })
      
      for(const r of records) {
        const {is_valid, message} = await this.isRecordValid(r, records)
        r.is_valid = is_valid;
        r.message = message || '';
        this.batchData.push(r)
      }
      this.tableLoading = false;
    },

    async validateOneRecord(r) {
      const {is_valid, message} = await this.isRecordValid(r, this.batchData)
      r.is_valid = is_valid;
      r.message = message || '';
      this.batchData.splice(r.index, 1, r)
    },

    onBatchFileUploadChange(event) {
      this.batchFileEL = event.target;
      if(event.target.files) {
        this.tableLoading = true
        this.batchFile = event.target.files[0];
      } 
    },

    async isRecordValid(record, records) {
      let found;
      
      for(let key of ['Name', 'MaxRiskFactorScore', 'DefaultScore']) {
        if(key == "Name" && this.isEmptyString(record[key])) {
          return {is_valid: false, message: `${key} cannot be empty`};
        } else if(key !== "Name" && (isNaN(record[key]) || record[key] < 0 || record[key] > 1000)) {
          return {is_valid: false, message: `${key} must be between 0 and 1000`};
        }
      }

      found = records.find( u => u.index !== record.index && u.Name == record.Name);
      if(found) return {is_valid: false, message: 'Duplicate Name found.'};
      
      return {is_valid: true};
    },

    isEmptyString(val) {
      val = val || false
      val = val ? val.toString() : val; 
      return (!val || val === undefined || val.length === 0 || !val.trim() )
    },

    async submit () {
      try {
        this.alertController.show = false;
        this.tableLoading = true;
        this.isLoading = true;
        let invalidCount = 0;
              
        let validBatch = this.batchData
          .filter( ({ is_valid }) => {
            invalidCount += is_valid ? 0 : 1;
            return is_valid 
          })
          .map((el) => {
            return {
              risk_factor_name: el.Name,
              risk_factor_score: el.MaxRiskFactorScore,
              default_risk_score: el.DefaultScore,
              risk_factor_is_active: el.RiskFactorScoringOn ? true : false,
              risk_factor_adjustment_is_active: el.RiskBasedLendingOn ? true : false
            }
          })

        if(validBatch.length == 0) {
          this.messageBox = {
            visible: true,
            icon: 'warning',
            color: 'error',
            title: 'No valid record found',
            message: '<span class="mt-2">Please check to ensure at least one record is valid then try again.</span>',
            confirmText: 'Ok',
            denyText: 'No',
            yesFunc: () => {
              this.messageBox.visible = false;
            },
            noFunc: null
          } 
          return; 
        } else if (invalidCount > 0) {
          this.messageBox = {
            visible: true,
            icon: 'warning',
            color: 'warning',
            title: `${invalidCount} Invalid records found`,
            message: '<span class="mt-2">Do you want to proceed with loading just the valid records?</span>',
            confirmText: 'Yes',
            denyText: 'No',
            yesFunc: async () => {
              this.messageBox.visible = false; 
              await this.saveRecords(validBatch);
            },
            noFunc:  () => {
              this.messageBox.visible = false; 
            },
          } 
          return; 
        } else {
          await this.saveRecords(validBatch);
        }
        
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
      }
    },

    async saveRecords(records) {
      try {
        this.tableLoading = true;
        this.isLoading = true;
        
        await RiskFactorService.batchSave(records)

        this.$emit('finished', true)

        this.messageBox = {
          visible: true,
          icon: 'done_all',
          color: 'primary',
          title: 'Batch Data Successfully Loaded',
          message: '<span class="mt-2">Click Ok to continue.</span>',
          confirmText: 'Ok',
          denyText: 'No',
          yesFunc: async () => {
            this.close(); 
          },
          noFunc:  null
        } 
      } catch(e) {
        console.log('saving/updating: ',e)
        this.alertController.message = 'Something went wrong while loading batch data. Please try again.'
        this.alertController.show = true;
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
      }
    }



  }
}
</script>

 
