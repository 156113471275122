import { render, staticRenderFns } from "./Adjustments-Settings.vue?vue&type=template&id=6b2d8409&scoped=true"
import script from "./Adjustments-Settings.vue?vue&type=script&lang=js"
export * from "./Adjustments-Settings.vue?vue&type=script&lang=js"
import style0 from "./Adjustments-Settings.vue?vue&type=style&index=0&id=6b2d8409&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2d8409",
  null
  
)

export default component.exports