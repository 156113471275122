import httpClient from '@/common/http'

export default {
  
  createNotificationSettings(obj) {
    return httpClient.request('post', 'settings/notifications/', obj);
  },

  updateNotificationSettings(id, updates) {
    return httpClient.request('patch', `settings/notifications/${id}`, updates);
  },

  getNotificationSettings() {
    return httpClient.request('get', 'settings/notifications/')
  },

  deleteNotificationSettings(id) {
    return httpClient.request('delete', `settings/notifications/${id}`)
  }
}