<template>
    <div>
        <v-dialog v-model="dialog" max-width="800px">
            <v-card>
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-flex>
                            <v-text-field label="Name" v-model="newType"></v-text-field>
                        </v-flex>
                        <v-flex v-if="showCard1">
                            <v-text-field label="Key Name" v-model="newKey"></v-text-field>
                        </v-flex>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialog = false">Close</v-btn>
                    <v-btn @click="validate(),dialog = false">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editDialog" max-width="800px">
            <v-card>
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-flex>
                            <v-text-field label="Type" v-model="editedItem.name"></v-text-field>
                        </v-flex>
                        <v-flex v-if="showCard1">
                            <v-text-field label="Key Name" v-model="editedItem.keyName"></v-text-field>
                        </v-flex>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="editDialog = false">Close</v-btn>
                    <v-btn @click="checkValue(),editDialog = false">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card class="mx-2 elevation-0">
            <v-flex>
                <v-card class="home-card" v-if="showCard1">
                    <v-card-title class="fixed-title" style="font-weight: bold;">
                        <v-btn @click="dialogTitle = 'Add New Sensitivity Types',dialog=true" class="ml-auto">
                            Add New Sensitivity Types
                        </v-btn>
                    </v-card-title>
                    <v-data-table class="mt-1" :items="data.sensitivity" :headers="sensitivityHeaders" :hide-actions="true">
                        <template slot="items" slot-scope="props">
                            <td>
                                {{ props.item.name | capitalize }}
                            </td>
                            <td>
                                {{ props.item.keyName | capitalize }}
                            </td>
                            <td>
                                <v-icon small
                                    @click="onReportOptionClick(props.item.name, props.index), showCard2 = true; showCard1 = false">
                                    fa-eye
                                </v-icon>
                            </td>
                            <td class="justify-center layout px-0">
                                <v-icon small class="mr-2"
                                    @click="editItem(props.item,data.sensitivity,dialogTitle = 'Edit Sensitivity Types')">
                                    edit
                                </v-icon>
                                <v-icon small @click="deleteItem(props.item,data.sensitivity)">
                                    delete
                                </v-icon>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-card>

        <v-card v-if="showCard2">
            <v-flex xs12 ma-2>
                <v-card>
                    <v-card-title class="fixed-title" style="font-weight: bold;">                        
                        <v-btn @click="showCard1 = true; showCard2 = false">Back</v-btn>
                        <v-btn @click="dialogTitle = 'Add Type',dialog=true" class="ml-auto">Add Type
                        </v-btn>
                    </v-card-title>
                        <v-subheader><b>{{ formTitle }}</b></v-subheader>
                    <v-data-table class="mt-1" :items="sensitivityTypeArr" :headers="sensitivityTypeHeaders" :hide-actions="true">
                        <template slot="items" slot-scope="props">
                            <td>
                                {{ props.item.name | capitalize }}
                            </td>
                            <td title="click to enable for use">
                                <v-checkbox @change="save()" v-model="props.item.required" primary hide-details>
                                </v-checkbox>
                            </td>
                            <td class="justify-center layout px-0">
                                <v-icon small class="mr-2"
                                    @click="editItem(props.item,sensitivityTypeArr,dialogTitle = 'Edit Type')">
                                    edit
                                </v-icon>
                                <v-icon title="click to delete" small @click="deleteItem(props.item,sensitivityTypeArr)">
                                    delete
                                </v-icon>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-flex xs12 ma-2>
                <v-card>
                    <v-card-title class="fixed-title" style="font-weight: bold;">
                        Material
                        <v-btn @click="dialogTitle = 'Add Material' , dialog=true" class="ml-auto">Add
                            Material</v-btn>
                    </v-card-title>
                    <v-data-table class="mt-1" :items="materialArr" :headers="sensitivityTypeHeaders" :hide-actions="true">
                        <template slot="items" slot-scope="props">
                            <td title="click to edit">
                                {{ props.item.name | capitalize }}
                            </td>
                            <td title="click to enable for use">
                                <v-checkbox @change="save()" v-model="props.item.required" primary hide-details>
                                </v-checkbox>
                            </td>
                            <td class="justify-center layout px-0">
                                <v-icon small class="mr-2"
                                    @click="editItem(props.item,materialArr,dialogTitle = 'Edit Material')">
                                    edit
                                </v-icon>
                                <v-icon small @click="deleteItem(props.item,materialArr)">
                                    delete
                                </v-icon>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-card>

    </div>
</template>

<script>
    import YapuService from "@/services/yapu-service";
    export default {
        data() {
            return {
                showCard1: true,
                showCard2: false,
                data: {
                    homeType: [],
                    businessType: [],
                    exposure: [],
                    sensitivity: [],
                    adaptiveCapacity: [],
                },
                dialog: false,
                dialogTitle: null,
                formTitle: null,
                sensitivityHeaders: [{
                        text: 'Name',
                        align: 'left',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: "Key Name",
                        value: 'keyName',
                        sortable: false,
                        align: "left"
                    },
                    {
                        text: "Sensitivity Details",
                        value: "details",
                        sortable: false,
                        align: "left"
                    },
                    {
                        text: "Actions",
                        value: "action",
                        sortable: false,
                        align: "center"
                    }
                ],

                sensitivityTypeHeaders: [{
                        text: '*Name',
                        value: 'name',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: "Required?",
                        value: 'required',
                        sortable: false,
                        align: "left"
                    },
                    {
                        text: "Delete",
                        value: "action",
                        sortable: false,
                        align: "center"
                    }
                ],
                selectedArr: [],
                editDialog: false,
                editedIndex: -1,
                editedItem: {
                    type: '',
                    keyName: ''
                },
                newType: null,
                newKey: null,
                index: null,
                materialArr: [],
                sensitivityTypeArr: [],
                originalItem: {}
            };
        },

        watch: {},

        computed: {},

        async mounted() {},

        created() {
            this.initialize();
        },

        methods: {

            async initialize() {
                try {
                    const result = await YapuService.findAll();
                    this.data = result[0] || [];

                    if (this.data.length === 0) {
                        await YapuService.create();
                        const updatedResult = await YapuService.findAll();
                        this.data = updatedResult[0] || [];
                    }
                } catch (error) {
                    console.error(error);
                }
            },

            onReportOptionClick(title, i) {
                this.formTitle = 'Sensitivity type : ' + ' ' + title;
                this.index = i;
                this.materialArr = this.data.sensitivity[this.index].material;
                this.sensitivityTypeArr = this.data.sensitivity[this.index].sensitivityType;
            },

            editItem(item, arr) {
                this.selectedArr = arr;
                this.editedIndex = this.selectedArr.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.originalItem = Object.assign({}, item);
                this.editDialog = true;
            },

            async checkValue() {
                if (this.showCard2) { this.checkDetails(); return; }
                // Loop through all properties of the edited item and compare them with the original item
                for (const prop in this.editedItem) {
                    if (this.editedItem.hasOwnProperty(prop)) {
                        // Trim and convert the case of the edited value (if it's a string)
                        const editedValue = typeof this.editedItem[prop] === 'string' ? this.editedItem[prop].trim().toLowerCase() : this.editedItem[prop];
                        const originalValue = typeof this.originalItem[prop] === 'string' ? this.originalItem[prop].trim().toLowerCase() : this.originalItem[prop];
                        if (editedValue !== originalValue) {
                            console.log(prop, 'changed from', originalValue, 'to', editedValue);
                            // Trim and convert the case of the value in each object in the array (if it's a string)
                            const valueExists = this.data.sensitivity.some(item => typeof item[prop] === 'string' && item[prop].trim().toLowerCase() === editedValue);
                            if (valueExists) {
                                // Find the item that matches the edited value
                                const matchingItem = this.data.sensitivity.find(item => typeof item[prop] === 'string' && item[prop].trim().toLowerCase() === editedValue);
                                this.$toast.error(`The edited value already exists in the sensitivity list: ${matchingItem[prop]}`, '', { position: 'topLeft' })
                                this.editedItem.type = null;
                                this.editedItem.keyName = null;
                                return;
                            }
                        }
                    }
                }

                if (this.editedIndex > -1) {
                    Object.assign(this.selectedArr[this.editedIndex], this.editedItem)
                } else {
                    this.selectedArr.push(this.editedItem)
                }

                // Reset the edited item and original item
                this.editedItem = {};
                this.originalItem = {};
                await this.save();
            },

            async checkDetails() {

                const trimmedName = this.editedItem.name.trim();
                const valid = await this.checkIfSensitivityExsits(trimmedName)

                if (valid) {
                    this.$toast.error(`Value already exists in the sensitivity details list.`, '', {
                        position: 'topLeft'
                    })
                    this.newType = null;
                    return;
                }
                if (this.editedIndex > -1) {
                    Object.assign(this.selectedArr[this.editedIndex], this.editedItem)
                } else {
                    this.selectedArr.push(this.editedItem)
                }
                await this.save();
            },

            async checkIfSensitivityExsits(val) {
                for (let i = 0; i < this.selectedArr.length; i++) {
                    if (this.selectedArr[i].name.toLowerCase() === val) {
                        return true;
                    }
                }
                return false;
            },

            async validate() {
                if(this.showCard2){ this.validateDetails(); return;}
                const trimmedName = this.newType.trim();
                const trimmedKey = this.newKey.trim();
                const valid = await this.checkExposure(trimmedName, trimmedKey)

                if (valid) {
                    this.$toast.error(`Value already exists in the sensitivity list.`, '', {
                        position: 'topLeft'
                    })
                    this.newType = null;
                    this.newKey = null;
                    return;
                }
                this.data.sensitivity.push({
                    name: trimmedName,
                    keyName: trimmedKey
                });
                this.newType = null;
                this.newKey = null;

                await this.save();
            },

            async validateDetails() {
                const type = this.dialogTitle === 'Add Material' ? 'material' : 'sensitivityType';  
                const trimmedName = this.newType.trim();
                const valid = await this.isValueExist(type, trimmedName)

                if (valid) {
                    this.$toast.error(`The value "${trimmedName}" already exists in the ${type} list.`, '', {
                        position: 'topLeft'
                    })
                    this.newType = null;
                    return;
                }
                this.data.sensitivity[this.index][type].push({
                    name: trimmedName
                });
                this.newType = null;

                await this.save();
            },

            async isValueExist(type, val) {
                return this.data.sensitivity[this.index][type].some(item => item.name.toLowerCase() === val.toLowerCase());
            },

            async checkExposure(val, key) {
                for (let i = 0; i < this.data.sensitivity.length; i++) {
                    if (this.data.sensitivity[i].name.toLowerCase() === val || this.data.sensitivity[i].keyName
                        .toLowerCase() === key) {
                        return true;
                    }
                }
                return false;
            },

            async save() {
                try {
                    await YapuService.update(this.data);
                    this.$toast.success("Record updated successfully", '', {
                        position: 'topLeft'
                    })
                } catch (error) {
                    this.$toast.error("An error occurred while saving data.", '', {
                        position: 'topLeft'
                    })
                    console.error(error);
                }
            },

            async deleteItem(item, arr) {
                const index = arr.indexOf(item)
                this.$toast.question("Are you sure you want to delete this record?", "", {
                    timeout: 20000,
                    close: false,
                    overlay: true,
                    position: "center",
                    buttons: [
                        [
                            "<button><b>YES</b></button>",
                            async (instance, toast) => {
                                    arr.splice(index, 1)
                                    this.save();
                                    instance.hide({
                                        transitionOut: "fadeOut"
                                    }, toast, "button");
                                },
                                true,
                        ],
                        [
                            "<button>NO</button>",
                            function (instance, toast) {
                                instance.hide({
                                    transitionOut: "fadeOut"
                                }, toast, "button");
                            },
                        ],
                    ],
                });
            },

        },
    };
</script>
<style scoped>
    .home-card {
        height: calc(100vh - 200px);
        overflow-y: auto;
        margin-right: 10px;
    }

    .business-card {
        height: calc(100vh - 200px);
        overflow-y: auto;
    }

    .fixed-title {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: rgb(241, 236, 236);
    }
</style>