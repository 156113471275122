<template>
<div>
    <v-card class="pa-2 mb-3">
        <h2>Loan Waiver Policy Settings</h2>
    </v-card>
    <v-layout row wrap>
        <v-flex xs12>
            <v-card class="elevation-1 mb-3 pl-2 pr-2 pt-2 pb-1">
                <v-layout row>
                    <v-flex xs12 sm3 mt-2>
                        
                        <v-switch
                            v-if="items.length > 0"
                            class="toggle-switch"
                            v-model="level_active"
                            @change="changeActiveLevel"
                            :label="level_active == false ? 'Off' : 'On'"
                        ></v-switch>
                        
                    </v-flex>
                    <v-flex xs12 sm5 mt-2>
                        <va-select
                            v-model="selected"
                            v-if="items.length > 0"
                            style="margin-top: -23px !important;"
                            @change="getData"
                            :items="items"
                        ></va-select>
                    </v-flex>
                    
                    <v-flex xs12 sm4 class="text-md-right">
                        <v-btn color="primary" @click="showAddLevelModal">Add Loan Waiver Level</v-btn>
                    </v-flex>
                    
                </v-layout>
            </v-card>
                <v-layout row>
                    <v-flex xs12 px-3 pt-2 pb-3>
                        <v-card v-if="items.length == 0">
                            <v-flex 
                                xs12 
                                pt-5 
                                class="text-xs-center"
                            >
                                <v-icon color="primary" size="70">fa-folder-open</v-icon>
                            </v-flex>
                            <v-flex xs12 pt-2 pb-4><h2 class="text-xs-center">Nothing Here</h2></v-flex>
                        </v-card>
                        <v-card v-if="items.length > 0">
                            <v-flex py-3>
                                <h2 class="text-xs-center">Waiver {{ selected }}</h2>
                            </v-flex>
                            <v-data-table
                                hide-actions
                                :headers="headers"
                                :items="settings"
                                :loading="waiverSettingsStore.loading"
                                class="v-table"
                                >
                                <template v-slot:items="props">
                                    <td pt-4>
                                        <div class="switches">
                                            <v-switch
                                                v-model="props.item.loan_waiver_active"
                                                @change="activateLoanWaiverName($event, props.item.loan_waiver)"
                                                :label="props.item.loan_waiver_active == false ? 'Off' : 'On'"
                                            ></v-switch> 
                                        </div>
                                    </td>
                                    <td>{{ props.item.loan_waiver }}</td>
                                    <td>
                                        <v-edit-dialog
                                            :return-value.sync="props.item.name"
                                            :large="props.item.staff_amount === '' ? false: true"
                                            lazy
                                            @save="props.item.staff_amount === ''? props.item.staff_amount = previous_amount :save(props.item.loan_waiver,staff_amount,'s')"
                                            @open="open(props.item.staff_amount), staff_amount = props.item.staff_amount >= 100 ? props.item.staff_amount : (props.item.staff_amount * 100).toFixed(0)"
                                            @cancel="previous_amount >= 100 ? props.item.staff_amount = previous_amount : props.item.staff_amount = (previous_amount/100).toFixed(2), cancel" 
                                            @close="close"
                                        > {{ (props.item.staff_amount * 100).toFixed(2) +'%' }} 
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="staff_amount"
                                                    label="Edit Staff Amount"
                                                    single-line
                                                    type="number"
                                                    counter
                                                    autofocus
                                                    required
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                    <td>
                                        <v-edit-dialog
                                            :return-value.sync="props.item.name"
                                            :large="props.item.member_amount === '' ? false: true"
                                            lazy
                                            @save="props.item.member_amount === ''? props.item.member_amount = previous_amount :save(props.item.loan_waiver,member_amount,'m')"
                                            @open="open(props.item.member_amount), member_amount = props.item.staff_amount >= 100 ? props.item.member_amount : (props.item.member_amount * 100).toFixed(0)"
                                            @cancel="previous_amount >= 100 ? props.item.member_amount = previous_amount : props.item.member_amount = (previous_amount/100).toFixed(2), cancel" 
                                            @close="close"
                                        > {{ (props.item.member_amount * 100).toFixed(2) +'%'}}
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="member_amount"
                                                    label="Edit Member Amount"
                                                    single-line
                                                    type="number"
                                                    counter
                                                    autofocus
                                                    required
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                        <td>
                                        <v-edit-dialog
                                            :return-value.sync="props.item.name"
                                            :large="props.item.volunteer_amount === '' ? false: true"
                                            lazy
                                            @save="props.item.volunteer_amount === ''? props.item.volunteer_amount = previous_amount :save(props.item.loan_waiver,volunteer_amount,'v')"
                                            @open="open(props.item.volunteer_amount), volunteer_amount = props.item.volunteer_amount >= 100 ? props.item.volunteer_amount : (props.item.volunteer_amount * 100).toFixed(0)"
                                            @cancel="previous_amount >= 100 ? props.item.volunteer_amount = previous_amount : props.item.volunteer_amount = (previous_amount/100).toFixed(2), cancel" 
                                            @close="close"
                                        > {{ (props.item.volunteer_amount * 100).toFixed(2) +'%'}}
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="volunteer_amount"
                                                    label="Edit Volunteer Amount"
                                                    single-line
                                                    type="number"
                                                    counter
                                                    autofocus
                                                    required
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                </template>
                            </v-data-table>
                            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                                {{ snackText }}
                                <v-btn flat @click="snack = false">Close</v-btn>
                            </v-snackbar>
                        </v-card> 
                    </v-flex>
                </v-layout>
        </v-flex>
    </v-layout>

       <v-dialog v-model="addLevelModal" max-width="500px">
            <v-card>
                <v-card-title class="primary el-border">
                    <v-layout align-center justify-center>
                        <h2 class="white--text">Add Loan Waiver Level</h2>
                    </v-layout>
                </v-card-title>
                <v-card-text class="el-border">
                    <v-flex xs12 style="margin-bottom:-30px;">
                        <va-input
                            placeholder="Enter Loan Waiver Level (Must be a number)"
                            v-model="waiver_level"
                            v-validate="'required|numeric|min_value:0|max_value:100'"
                            data-vv-name="waiver_level"
                            data-vv-as="Waiver Level"
                            data-vv-scope="waiver_level"
                            :error-messages="errors.collect('waiver_level')"
                            type="number"
                        ></va-input>
                    </v-flex>
                </v-card-text>
                <v-layout class="pa-2" align-end justify-end>
                    <v-btn :loading="waiverSettingsStore.loading" color="primary" @click="saveWaiverLevel">Add</v-btn>
                    <v-btn color="error" @click="closeAddLevelModal">Cancel</v-btn>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>
<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";

export default {
    name: 'LoanWaiverPolicySettings',
    components: {
        VaInput,
        VaSelect
    },
    data: () => ({
        dialog:true,
        items: [],
        settings: [],
        loan_waiver:'',
        selected: '',
        waiver_level: '',
        level_active: false,
        previous_amount: null,
        staff_amount:null,
        member_amount:null, 
        volunteer_amount:null,
        levelIsValid: false,
        addLevelModal: false,
        showInlineDialog: false,
        showLoader: false,
        headers: [
            {
                text: 'Off / On',
                value: 'toggle',
                align: 'left',
                sortable: false
            },
            { 
                text: 'Loan Waiver Name', 
                value: 'loan_waiver_name', 
                align: 'left',
                sortable: false
            },
            { 
                text: 'Staff', 
                value: 'staff', 
                align: 'left',
                sortable: false
            },
            { 
                text: 'Member', 
                value: 'member', 
                align: 'left',
                sortable: false
            },
            { 
                text: 'Volunteer', 
                value: 'volunteer', 
                align: 'left',
                sortable: false
            }
        ],
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!'
    }),
    methods: {
        ...mapGetters([
            'getAllWaiverLevels'
        ]),
        ...mapActions([
            'createWaiverLevel',
            'findWaiverSettings',
            'findAllWaiverLevels',
            'saveWaiverAmount',
            'saveWaiverLevelActive',
            'saveLoanWaiverNameActive'
        ]),
        async saveWaiverLevel() {
            this.level_active = false
            this.levelIsValid = await this.$validator.validateAll('waiver_level')
            if(this.levelIsValid) {
                try {
                    const obj = {
                        waiver_level: this.waiver_level,
                        level_active: this.level_active
                    }
                    const result = await this.createWaiverLevel(obj)
                    if(result) {
                        let select = 'Level ' + this.waiver_level
                        this.items.push(select)
                        this.selected = select
                        this.settings = this.waiverSettingsStore.settings
                        this.addLevelModal = false
                        this.$toast.success('Waiver Level Added', '', { position: 'topCenter' })
                    }
                    else {
                        this.$toast.error('Waiver Level already exist', '', { position: 'topCenter' })
                    }
                    
                }
                catch(error) {
                    try {
                        const err = await error
                        const msg = err.response.data.error
                        this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                    }
                    catch(e) {
                        this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                    }
                }
            }
            else {
                this.$toast.error(`${this.$validator.errors.items[0].msg}`, '', {position: 'topCenter'})
            }
        },
        async save (loan_waiver, amount, title) {
            try {
                let obj = {
                    waiver_level: this.selected,
                    loan_waiver: loan_waiver,
                    amount : amount,
                    title : title
                }
                let result = await this.saveWaiverAmount(obj)
                if(result) {
                    this.getSettings(this.selected.charAt(this.selected.length-1)) 
                    this.$toast.success('Update was successful', '', { position: 'topCenter' })
                    this.showInlineDialog = false
                }
            }
            catch(error) {
                try {
                    const err = await error
                    const msg = err.response.data.error
                    this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                }
                catch(e) {
                    this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                }
            }
            
        },
        cancel () {
            this.showInlineDialog = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Canceled'
        },
        open (amount) {
            this.showInlineDialog = false
            this.previous_amount = amount >= 100 ? amount : (amount*100).toFixed(2)
            this.showInlineDialog = true
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Dialog opened'
        },
        close () {
            console.log('Dialog closed')
        },
        showAddLevelModal() {
            this.waiver_level = ''
            this.addLevelModal = true
        },
        closeAddLevelModal() {
            this.addLevelModal = false
        },
        async init() {
            try {
                let result = await this.findAllWaiverLevels()
                if(result) {
                    let arr = this.waiverSettingsStore.levels
                    let data = Object.values(arr[0])
                    this.items = data.map(res => res = 'Level ' + res)
                    this.selected = this.items[0]
                    if(arr.length > 0) {
                    this.getSettings(this.selected.charAt(this.selected.length-1)) 
                    }
                }
            }
            catch(error) {
                try {
                    const err = await error
                    const msg = err.response.data.error
                    this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                }
                catch(e) {
                    this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                }
            }
        },
        async getSettings(selected) {
            try {
                let result = await this.findWaiverSettings(selected)
                if(result) {
                    this.settings = this.waiverSettingsStore.settings
                    this.level_active = this.settings[0].level_active
                }
            }
            catch(error) {
                try {
                    const err = await error
                    const msg = err.response.data.error
                    this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                }
                catch(e) {
                    this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                }
            }
        },
        async getData() {
            try {
                let result = await this.findWaiverSettings(this.selected.charAt(this.selected.length-1))
                if(result) {
                    this.settings = this.waiverSettingsStore.settings
                    this.level_active = this.settings[0].level_active
                }
            } 
            catch(error) { 
                try {
                    const err = await error
                    const msg = err.response.data.error
                    this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                }
                catch(e) {
                    this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                }
            }
        },
        async changeActiveLevel(event) {
            this.level_active = event
            try {
                let obj = {
                    waiver_level: this.selected,
                    level_active: this.level_active,
                }
                let result = await this.saveWaiverLevelActive(obj)
                if(result) {
                    switch(this.level_active) {
                        case true:
                            this.$toast.success('Waiver level is active', '', { position: 'topCenter' })
                            break
                        case false:
                            this.$toast.warning(`Warning: Waiver level deactivated. 
                                                You will not be able to use this level`, '', { position: 'topCenter' })
                            break
                    }
                }
            } 
            catch(error) { 
                try {
                    const err = await error
                    const msg = err.response.data.error
                    this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                }
                catch(e) {
                    this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                }
            }
        },
        async activateLoanWaiverName(event, loan_waiver) {
            try {
                let obj = {
                    waiver_level: this.selected,
                    loan_waiver: loan_waiver,
                    loan_waiver_active: event,
                }
                let result = await this.saveLoanWaiverNameActive(obj)
                if(result) {
                    switch(event) {
                        case true:
                            this.$toast.success(`${loan_waiver} loan waiver name is active`, '', { position: 'topCenter' })
                            break
                        case false:
                            this.$toast.warning(`Warning: ${loan_waiver} loan waiver name deactivated. 
                                                Amounts for this loan waiver name is not available`, '', { position: 'topCenter' })
                            break
                    }
                }
            } 
            catch(error) { 
                try {
                    const err = await error
                    const msg = err.response.data.error
                    this.$toast.error(`${ msg }`, '', { position: 'topCenter' })
                }
                catch(e) {
                    this.$toast.error('Network Error. Please check your internet connection', '', { position: 'topCenter' })
                }
            }
        }
    },
    computed: {
        ...mapState([
            'waiverSettingsStore',
        ])
    },
    mounted() {
        this.init()
    },
    watch: {

    }
}
</script>

<style scoped>
    .toggle-switch {
        width: 100px;
        height: 37px !important; 
        margin: 0px !important; 
        border-radius: 5px;
        padding-left: 10px !important;
        background: #f7f7f7 !important;
        border: 2px solid #e3e3e3;
    }

    .switches {
        /* width:40px !important; 
        margin: 0px auto; */
        padding-top: 15px; 
        padding-bottom: -10px; 
    }
</style>
