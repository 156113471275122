import httpClient from '@/common/http'
export default {
  findLoanSettings() {
    return httpClient.request('get', '/settings/general/loan')
  },

  findAccountSettings() {
    return httpClient.request('get', '/settings/general/account')
  },

  updateLoanSettings(obj) {
    return httpClient.request('post', `/settings/general/loan`, obj)
  },

  createAccountSettings(obj) {
    return httpClient.request('post', `/settings/general/account/`, obj)
  },

  updateAccountSettings(id, obj) {
    return httpClient.request('put', `/settings/general/account/${id}`, obj)
  }
}