import httpClient from '@/common/http'
export default {

  create(obj) {
    return httpClient.request('post', 'accounts', obj);
  },

  update(id, obj) {
    return httpClient.request('put', `accounts/${id}`, obj);
  },

  findOne(id) {
    return httpClient.request('get', `accounts/${id}`)
  },

  findAll(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
    return httpClient.request('get', `accounts?${filter}${query}`)
  }

}