<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">

      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Edit Loan</v-btn>
      </template> -->

      <v-card>
        <v-toolbar dark flat color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Quick Loan Editor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="dialog = false">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <iframe class="browser" :src=url frameborder="0"></iframe>
        <!-- <embed :src=url type=""> -->

        <!-- <iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;" :src="url"></iframe> -->

      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
    data () {
      return {
        dialog: false,
        url: 'http://localhost:8080/edit-loans',
      }
    }
}
</script>

<style>
    .browser{
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>