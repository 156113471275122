<template>
 <div>
  <v-card class="pa-2 pl-4 mb-3 elevation-0">
    <h2>ID Types</h2>
  </v-card>
  <v-dialog v-model="modal" persistent max-width="360">
    <v-toolbar  dense color="primary" dark tabs>
      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-toolbar>
    <v-card>
      <v-card-text class="mb-4">

        <va-input
          v-model="item.id_type"
          v-validate="'required|min:3|max:45'"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.id_type')"
          placeholder=" "
          label="ID Type"
          data-vv-validate-on="blur"         
          data-vv-name="id_type"
          data-vv-as="ID type"
          show-clean />

      </v-card-text>

      <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
        <v-spacer></v-spacer>
        <v-btn flat @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" @click="submit">{{item.ID ? 'Update' : 'Save'}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-toolbar dense color="white" class="pa-0">
    <v-text-field
      v-model="search"
      solo
      label="Search"
      prepend-inner-icon="search"
    ></v-text-field>

    <v-divider class="mr-2" vertical></v-divider>

    <v-btn  color="primary" @click="openModal">
      ADD TYPE
    </v-btn>
  </v-toolbar>
  <v-data-table 
    hide-actions
    :loading="isLoading"
    :search="search"
    :headers="tblHeaders" 
    :items="items" 
    class="elevation-1">
    <template slot="items" slot-scope="props">
      <td class="text-xs-left">{{ props.item.id_type }}</td>
      <td >
        <v-icon small class="mr-2" @click="edit(props.item)">
          edit
        </v-icon>
        <v-icon small class="mr-2" @click="onDeleteItemClicked(props.item)">
          delete
        </v-icon>
      </td>
    </template>
  </v-data-table>
  
  <v-dialog v-model="deleteDialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <h3 class="text-xs-center pb-3">In order to delete this ID type you must select a replacement type.</h3>  
        
        <va-select 
          v-model="replacementID"
          v-validate="'required'"
          placeholder=" "
          label="Select a replacement ID type"
          data-vv-scope="delete"
          data-vv-as="replacement account type"
          :error-messages="errors.collect('delete.replacement')"
          :items="items.filter( (el) => el.ID !== item.ID )"
          :item-text="'id_type'"
          :item-value="'ID'"
          data-vv-name="replacement"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="red darken-3" flat @click="deleteItem">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<style type="css" scoped>
  h1, h2, h3 {
    color: #505a6b; }
</style>

<script>
import { mapState, mapMutations } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import IDService from '@/services/id-type-service'
export default {
  components: {
    VaInput,
    VaSelect
  },

  created () {
    this.initialize()
  },
  
  data() {
    return {
      modal: false,
      isLoading: false,
      deleteDialog: false,
      replacementID: '',
      search: '',
      tblHeaders: [
        { text: 'Code', align: 'left', sortable: false, value: 'id_type'},
        { text: 'Action', align: 'left', sortable: false },
      ],
      defaultItem: {
        id_type: "",
      },
      item: {},
      items: [],
    }
  },

  computed: {
    ...mapState([
      'appState',
    ]), 
    
    formTitle () {
      return this.item.ID ? 'Edit ID Type' : 'Add ID Type'
    }
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    openModal() {
      this.$toast.destroy()
      this.item = {...this.defaultItem}
      this.modal = true
    },

    closeModal() {
      this.$validator.reset()
      this.item = {...this.defaultItem}
      this.modal = false
    },

    edit(item) {
      this.item = {...item}     
      this.modal = true
    },

    async initialize() {
      await this.fetchData()
    },

    onDeleteItemClicked(item) {
      this.item = {...item}
      this.deleteDialog = true
    },

    async deleteItem() {
      let isValid = await this.$validator.validateAll("delete")
      if(!isValid) return
      
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      this.$toast.question('Are you sure you want to delete this ID type?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              await IDService.delete(this.item.ID, this.replacementID)

              let len = this.items.length
              for(let i = 0; i < len; i++) {
                if(this.item.ID == this.items[i].ID) {
                  this.items.splice(i,1)
                  break
                }
              }
              this.deleteDialog = false

              this.$toast.warning('Account has been deleted!', '', {position: 'topCenter'})
            } catch(e) {
              console.log('deleting: ',e)
              if(e.response) {
                if(e.response.status === 404)
                  this.$toast.error("Account has been already deleted", '',{position: 'center'})
                else
                  this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              }
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
      
    },

    async submit() {
    
      let isValid = await this.$validator.validateAll("loan")
      if(isValid) {
        await this.save()
      } else {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
      }
    },
    toggleSwitch(item) {
      this.item = {...item}
      this.save()
    },

    async save () {
      try {
        this.isLoading = true
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let data = {...this.item}

        delete data['date_created']
        delete data['date_updated']

        if (this.item.ID) {
          await IDService.update(this.item.ID, data)
          let len = this.items.length
          for(let i = 0; i < len; i++) {
            if(this.item.ID == this.items[i].ID) 
              this.items.splice(i, 1, {...this.item})
          }
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          let res = await IDService.create(data)
          this.item.ID = res.data
          this.items.push(this.item)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        this.closeModal()
       
      } catch(e) {
        console.log('saving/updating: ',e)
        if(e.response) {
          if(e.response.status === 409)
            this.$toast.error(e.response.data.error.message, '',{position: 'center'})
          else
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      } finally  {
        this.isLoading = false
      }
    },

    async fetchData() {
      try {
        this.isLoading = true
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let response = await IDService.findAll() 
        this.items = response.data
      } catch(e) {
        console.log('fetching: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },
  },

}
</script>

 
