import httpClient from '@/common/http'

export default {
  massUpload(opt,obj) {
     return httpClient.request('post', `settings/mass_upload/initial/${opt}`, obj);
  },
  //massUpload(opt,obj, onUploadProgress) {
    //return httpClient.uploadClient().post(`upload/file/${opt}`, obj, {
      //headers: {"Content-Type": "multipart/form-data"}, 
      //onUploadProgress
    //});
  //},
  // transactionUpload(opt,obj) {
  //   return httpClient.request('post', `settings/transaction_upload/initial/${opt}`, obj);
  // },
  transactionUpload(obj, onUploadProgress) {
    return httpClient.uploadClient().post(`upload/transactions/file`, obj, {
      headers: {"Content-Type": "multipart/form-data"}, 
      onUploadProgress
    });
  },
}
