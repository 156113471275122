<template>
  <div>   
    <v-dialog v-model="batchInsertDialog" fullscreen persistent>
      <v-card>
       <v-progress-linear height="3" class="ma-0" value="100"></v-progress-linear>
        <v-card-title class="mb-0 pb-0 pt-4">
          <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          <h1>
          User Batch Data Load
          </h1>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-alert dismissible :value="alertController.show" :type="alertController.type" class="main-alert">
            {{alertController.message}}
          </v-alert>
          <v-dialog v-model="messageBox.visible" max-width="390" persistent>
            <v-card>
              <v-card-title class="headline">
                <!-- <v-icon :color="messageBox.color" class="mr-2" large>{{messageBox.icon}}</v-icon> -->
                {{messageBox.title}}
              </v-card-title>
              <v-card-text class="mt-0 pt-0 pb-0">
                <span v-html="messageBox.message"></span>
              </v-card-text>
              <v-card-actions class="mt-0 pt-2">
                  <v-spacer></v-spacer>
                  <v-btn v-if="messageBox.noFunc" flat @click="messageBox.noFunc">
                    {{messageBox.denyText}}
                  </v-btn>
                  <v-btn :loading="messageBox.btnLoading" :color="messageBox.color" flat @click="messageBox.yesFunc">
                    {{messageBox.confirmText}}
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <h3>Insert or updates user details from excel file. Click the button below to choose a file.</h3>
          <input class="mt-2 mb-2" type="file" @click="onFileClicked" @change="onBatchFileUploadChange" />

          <v-layout>
            <v-flex>
              <v-btn color="primary darken-1" class="ml-0" :href="templateUrl" download="Batch_User_Template.xlsx" dark>
                <v-icon class="mr-2">cloud_download</v-icon>
                Download Template
              </v-btn>
              <v-btn  :loading="isLoading" color="primary" :disabled="isLoading || batchData.length == 0" @click="submit">
                <v-icon class="mr-2">cloud_upload</v-icon>
                Upload
              </v-btn>
            </v-flex>
            <v-flex xs6 sm4>
              <v-text-field 
              label="Search" 
              v-model="searchTableModel"
              prepend-icon="search" :width="500" />
            </v-flex>
          </v-layout>
          
          <xlsx-read :file="batchFile">
            <xlsx-json :sheet="selectedSheet" @parsed="onBatchDataParsed">
              <template>
                <div>
                <v-data-table 
                  class="elevation-0" 
                  :headers="batchInsertHeaders" 
                  :search="searchTableModel"
                  :loading="tableLoading"
                  :items="batchData || []">
                  <template slot="items" slot-scope="props" >
                    <tr :title="props.item.message" :class="{batchNotValid: !props.item.is_valid}" >
                      <td>
                        <v-checkbox v-model=" props.item.Activated" primary hide-details ></v-checkbox>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)" large lazy :return-value.sync="props.item.ID" > 
                          {{ props.item.ID || '-'  }}
                          <v-text-field
                            slot="input"
                            v-validate="'required|max:20'" 
                            data-vv-scope="batch"
                            data-vv-name="ID"
                            :error-messages="errors.collect('batch.ID')"
                            v-model="props.item.ID"  
                            label="ID" 
                            single-line 

                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog 
                          @save="validateOneRecord(props.item)" 
                          large 
                          lazy 
                          :return-value.sync="props.item.TRN" 
                          > 
                          {{ props.item.TRN || '-'  }}
                          <v-text-field
                            slot="input"
                            v-validate="'required|min:3|max:20'" 
                            data-vv-scope="batch"
                            data-vv-name="trn"
                            :error-messages="errors.collect('batch.trn')"
                            v-model="props.item.TRN"  
                            label="TRN" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>{{ props.item.FullName || '-' }}</td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.Email" > 
                          {{ props.item.Email || '-'  }}
                          <v-text-field
                            slot="input"
                            v-validate="'required|email'" 
                            data-vv-scope="batch"
                            data-vv-name="email"
                            :error-messages="errors.collect('batch.email')"
                            v-model="props.item.Email"  
                            label="Email" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.BranchCode" > 
                          {{ props.item.BranchCode || '-'  }}
                          <v-select
                            slot="input" 
                            :items="branches" 
                            :item-value="`branch_code`"
                            :item-text="`branch_code`"
                            v-validate="'required'" 
                            data-vv-scope="batch"
                            data-vv-name="branch"
                            :error-messages="errors.collect('batch.branch')"
                            v-model="props.item.BranchCode"  
                            label="Branch" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.Role" > 
                          {{ props.item.Role || '-'  }}
                          <v-select
                            slot="input" 
                            :items="roles" 
                            :item-value="`role_name`"
                            :item-text="`role_name`"
                            v-validate="'required'" 
                            data-vv-scope="batch"
                            data-vv-name="role"
                            :error-messages="errors.collect('batch.role')"
                            v-model="props.item.Role"  
                            label="Role" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.WaiverLevel" > 
                          {{ props.item.WaiverLevel || '-'  }}
                          <v-select
                            slot="input" 
                            :items="waiverLevels" 
                            :item-value="`waiver_level`"
                            :item-text="`waiver_level`"
                            data-vv-scope="batch"
                            data-vv-name="waiver_level"
                            data-vv-as="waiver level"
                            :error-messages="errors.collect('batch.waiver_level')"
                            v-model="props.item.WaiverLevel"  
                            label="Waiver Level" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.ApprovalLevel" > 
                          {{ props.item.ApprovalLevel || '-'  }}
                          <v-select
                            slot="input" 
                            :items="approvalLevels" 
                            :item-value="`approval_level`"
                            :item-text="`approval_level`"
                            data-vv-scope="batch"
                            data-vv-name="approval_level"
                            data-vv-as="approval level"
                            :error-messages="errors.collect('batch.approval_level')"
                            v-model="props.item.ApprovalLevel"  
                            label="Approval Level" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.DsrWaiverLevel" > 
                          {{ props.item.DsrWaiverLevel || '-'  }}
                          <v-select
                            slot="input" 
                            :items="dsrWaiverLevels" 
                            :item-value="`waiver_level`"
                            :item-text="`waiver_level`" 
                            data-vv-scope="batch"
                            data-vv-name="dsr_waviver_level"
                            data-vv-as="dsr waiver level"
                            :error-messages="errors.collect('batch.dsr_waviver_level')"
                            v-model="props.item.DsrWaiverLevel"  
                            label="DSR Waiver Level" 
                            single-line 
                            />
                        </v-edit-dialog> 
                      </td>
                      <td>
                        <v-edit-dialog @save="validateOneRecord(props.item)"  large lazy :return-value.sync="props.item.InsuranceWaiverLevel" > 
                          {{ props.item.InsuranceWaiverLevel || '-'  }}
                          <v-select
                            slot="input" 
                            :items="insuranceWaiverLevels" 
                            :item-value="`waiver_level`"
                            :item-text="`waiver_level`" 
                            data-vv-scope="batch"
                            data-vv-name="insurance_waviver_level"
                            data-vv-as="insurance waiver level"
                            :error-messages="errors.collect('batch.insurance_waviver_level')"
                            v-model="props.item.InsuranceWaiverLevel"  
                            label="Insurance Waiver Level" 
                            single-line 
                            />
                        </v-edit-dialog>
                      </td>
                    </tr>
                    
                  </template>
                  <template slot="no-data">
                    
                  </template>
                </v-data-table>
                </div>
              </template>
            </xlsx-json>
          </xlsx-read> 
        </v-card-text>
        
      </v-card>
    </v-dialog> 
  </div>
</template>

<style type="css">
h1, h2, h3 {
color: #505a6b;}

.batchNotValid {
  background: #fdc4ac !important;}

.main-alert {
  z-index: 99999;
  margin:0;
  padding-top: 5px; 
  padding-bottom: 5px; 
  padding-left: 10px;
  padding-right: 10px;
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0;}
</style>

<script>
import CustomerService from "@/services/customer-service";
import BranchService from "@/services/branch-service";
import RoleService from "@/services/role-service";
import UserService from "@/services/user-service";
import WaiverLevelService from '@/services/loan-waiver-policy-setting.service'
import ApprovalLevelService from '@/services/loan-approval-policy.service'
import { Validator } from 'vee-validate';
import _ from 'lodash';

import {XlsxRead, XlsxJson} from "vue-xlsx";
export default {
  components: {
    XlsxRead,
    XlsxJson
  },

  created () {
    this.validator = new Validator();
    this.initialize();
  },

  data() {
    return {
      validator: null,

      templateUrl: `${process.env.VUE_APP_ROOT_API.replace('/api/v1', '')}public/assets/templates/users_template.xlsx`,
      batchInsertDialog: false,
      searchTableModel: '',
      isLoading: false,
      tableLoading: false,
      selectedSheet: 'Users',
      batchData: [],
      batchFile: null,
      batchFileEL: null,
      batchLength: 0,
      batchInsertDialog: false,
      batchInsertHeaders: [
        { text: 'Activate', align: 'left', sortable: false},
        { text: 'ID', align: 'left', sortable: false, value: 'ID'},
        { text: 'TRN', align: 'left', sortable: false, value: 'FullName'},
        { text: 'Name', align: 'left', sortable: false, value: 'TRN'},
        { text: 'Email', align: 'left', sortable: false, value: 'Email'},
        { text: 'Branch Code', align: 'left', sortable: false, value: 'BranchCode'},
        { text: 'Role', align: 'left', sortable: false, value: 'Role'},
        { text: 'Waiver Level', value: '', sortable: false, value: 'WaiverLevel'},
        { text: 'Approval Level', value: '', sortable: false, value: 'ApprovalLevel'},
        { text: 'Dsr Waiver Level', value: '', sortable: false, value: 'DsrWaiverLevel'},
        { text: 'Insurance Waiver Level', value: '', sortable: false, value: 'InsuranceWaiverLevel'},
      ],
  
      users: [],
      branches: [],
      roles: [],
      waiverLevels: [],
      approvalLevels: [],
      dsrWaiverLevels: [],
      insuranceWaiverLevels: [],
      searchCustomerModel: null,

      messageBox: {
        visible: false,
        icon: '',
        color: '',
        title: '',
        message: '',
        confirmText: 'Ok',
        denyText: 'No',
        yesFunc: function() {},
        noFunc: function() {}
      },
      alertController: {
        show: false,
        type: 'error',
        message: 'Something went wrong loading page. Please refresh the page and try again.'
      },
    }
  },

  computed: {},

  methods: {
    open() {
      this.batchInsertDialog = true;
      this.batchData = [];
      this.batchFile = null;
    },

    close() {
      this.batchInsertDialog = false;
      this.batchData = [];
      this.batchFile = null;
      if(this.batchFileEL)
        this.batchFileEL.value = "";
    },

    onFileClicked() {
      if(this.batchFileEL) {
        this.batchData = [];
        this.batchFileEL.value = "";
      }
    },

    async onBatchDataParsed(records) {
      this.batchLength = records.length;
      if(records.length > 1200) {
        this.messageBox = {
          visible: true,
          icon: 'warning',
          color: 'warning',
          title: 'Batch Limit Exceeded',
          message: '<b class="mt-2">Cannot exceed 1200 records<b>',
          confirmText: 'Ok',
          denyText: 'No',
          yesFunc: () => {
            this.messageBox.visible = false;
          },
          noFunc: null
        }
        this.tableLoading = false;
        return;
      }

      records = records.map((r, i) => {
        r.index = i;
        return r;
      })
      
      for(const r of records) {
        const {is_valid, message} = this.isRecordValid(r, records)
        r.is_valid = is_valid;
        r.message = message || '';
        this.batchData.push(r)
      }
      this.tableLoading = false;
    },

    validateOneRecord(r) {
      const {is_valid, message} = this.isRecordValid(r, this.batchData)
      r.is_valid = is_valid;
      r.message = message || '';
      this.batchData.splice(r.index, 1, r)
    },

    onBatchFileUploadChange(event) {
      this.batchFileEL = event.target;
      this.batchFile = null;
      if(event.target.files) {
        this.tableLoading = true
        this.batchFile = event.target.files[0];
      } 
    },
    
    async initialize () {   
      try {
        await Promise.all([
          this.fetchUsers(),
          this.fetchBranches(),
          this.fetchRoles(),
          this.fetchWaiverLevels(),
          this.fetchApprovalLevels(),
          this.fetchDsrWaiverLevels(),
          this.fetchInsuranceWaiverLevels(),
        ]);

      } catch(e) {
        console.log({e})
      }
    },

    async fetchBranches() {
       try {
        let response = await BranchService.findAll();
        this.branches = response.data;
      } catch(e) {
        throw e
      } 
    },

    async fetchRoles() {
      try {
        let response = await RoleService.findAll();
        this.roles = response.data;
      } catch(e) {
        throw e
      } 
    },
    
    async fetchWaiverLevels() {
       try {
        let response = await WaiverLevelService.findAll();
        this.waiverLevels = response.reduce((acc, cur)=>{
          acc = acc.concat(cur)
          return acc
        });
        this.waiverLevels.push('')
      } catch(e) {
        throw e
      } 
    },

    async fetchApprovalLevels() {
       try {
        let response = await ApprovalLevelService.findAll();
        this.approvalLevels = response.reduce((acc, cur)=>{
          acc = acc.concat(cur)
          return acc
        });
        this.approvalLevels.push('')
      } catch(e) {
        throw e
      } 
    },

    async fetchDsrWaiverLevels() {
       try {
        let response = await WaiverLevelService.getDSRWaiver();
        this.dsrWaiverLevels = response.map(({ waiver_level }) => waiver_level)
        this.dsrWaiverLevels.push('')
      } catch(e) {
        throw e
      } 
    },

    async fetchInsuranceWaiverLevels() {
       try {
        let response = await WaiverLevelService.getInsuranceWaiver();
        this.insuranceWaiverLevels = response.map(({ waiver_level }) => waiver_level)
        this.insuranceWaiverLevels.push('')
      } catch(e) {
        throw e
      } 
    },

    async fetchUsers() {
      try {
        let response = await UserService.findAll('', '', {skip: this.skip, limit: this.limit})
        this.users = response.data
      } catch(e) {
        throw e;
      } 
    },

    isRecordValid(record, records) {
      let found;
      
      for(let key of ['ID', 'TRN', 'Email', 'BranchCode', 'Role']) {
        if(this.isEmptyString(record[key])) {
          return {is_valid: false, message: `${key} cannot be empty`};
        }
      }

      found = records.find( u => u.index !== record.index && u.ID == record.ID);
      if(found) return {is_valid: false, message: 'Duplicate ID found.'};

      if(!record.Email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
       return {is_valid: false, message: 'Email is not valid'};

      found = this.users.find( u => u.person && u.person.trn != record.TRN && u.email === record.Email);
      if(found) return {is_valid: false, message: 'Email address already exist in our system'};

      let len = this.batchLength;
      while(len--) {
        if(records[len].index !== record.index && records[len].TRN == record.TRN) {
          return {is_valid: false, message: 'Duplicate TRN found.'};
        } else if (records[len].index !== record.index && records[len].Email == record.Email) {
          return {is_valid: false, message: 'Duplicate email address found'};
        }
      }

      found = this.roles.find(r => r.role_name === record.Role) || false
      if(!found && record.role_name) return {is_valid: false, message: 'This role does not exist in our system'};

      found = this.branches.find(r => r.branch_code === record.BranchCode) || false
      if(!found && record.BranchCode) return {is_valid: false, message: 'This branch code does not exist in our system'};

      found = this.waiverLevels.find(r => r == record.WaiverLevel) || false
      if(!found && record.WaiverLevel) return {is_valid: false, message: 'This waiver level does not exist in our system'};

      found = this.approvalLevels.find(r => r == record.ApprovalLevel) || false
      if(!found && record.ApprovalLevel) return {is_valid: false, message: 'This approval level does not exist in our system'};

      found = this.dsrWaiverLevels.find(r => r == record.DsrWaiverLevel) || false
      if(!found && record.DsrWaiverLevel) return {is_valid: false, message: 'This dsr waiver level does not exist in our system'};

      found = this.insuranceWaiverLevels.find(r => r == record.InsuranceWaiverLevels) || false
      if(!found && record.InsuranceWaiverLevel) return {is_valid: false, message: 'This insurance waiver does not exist in our system'};
      
      return {is_valid: true};
    },

    isEmptyString(val) {
      val = val || false
      val = val ? val.toString() : val; 
      return (!val || val === undefined || val.length === 0 || !val.trim() )
    },

    async submit () {
      try {
        this.alertController.show = false;
        this.tableLoading = true;
        this.isLoading = true;
        let invalidCount = 0;
              
        let validBatch = this.batchData
          .filter( ({ is_valid }) => {
            invalidCount += is_valid ? 0 : 1;
            return is_valid 
          })
          .map((el) => {
            return {
              test: true,
              ID: el.ID,
              trn: el.TRN.toString(),
              is_active: el.Activated ? true : false,
              email: el.Email,
              branch_code: el.BranchCode,
              role: el.Role || '-',
              approval_level: !this.isEmptyString(el.ApprovalLevel) ? el.ApprovalLevel.toString() : '-',
              waiver_level: !this.isEmptyString(el.WaiverLevel) ? el.WaiverLevel.toString() : '-',
              dsr_waiver_level: !this.isEmptyString(el.DsrWaiverLevel) ? el.DsrWaiverLevel.toString() : '-',
              insurance_waiver_level: !this.isEmptyString(el.InsuranceWaiverLevel) ? el.InsuranceWaiverLevel.toString() : '-',
            }
          })

        if(validBatch.length == 0) {
          this.messageBox = {
            visible: true,
            icon: 'warning',
            color: 'error',
            title: 'No valid record found',
            message: '<span class="mt-2">Please check to ensure at least one record is valid then try again.</span>',
            confirmText: 'Ok',
            denyText: 'No',
            yesFunc: () => {
              this.messageBox.visible = false;
            },
            noFunc: null
          } 
          return; 
        } else if (invalidCount > 0) {
          this.messageBox = {
            visible: true,
            icon: 'warning',
            color: 'warning',
            title: `${invalidCount} Invalid records found`,
            message: '<span class="mt-2">Do you want to proceed with uploading just the valid records?</span>',
            confirmText: 'Yes',
            denyText: 'No',
            yesFunc: async () => {
              this.messageBox.visible = false; 
              await this.saveRecords(validBatch);
            },
            noFunc:  () => {
              this.messageBox.visible = false; 
            },
          } 
          return; 
        } else {
          await this.saveRecords(validBatch);
        }
        
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
      }
    },

    async saveRecords(records) {
      try {
        this.tableLoading = true;
        this.isLoading = true;
        
        await UserService.batchSave(records)

        this.$emit('finished', true)

        this.messageBox = {
          visible: true,
          icon: 'done_all',
          color: 'primary',
          title: 'Batch Data Successfully Inserted',
          message: '<span class="mt-2">Click Ok to continue.</span>',
          confirmText: 'Ok',
          denyText: 'No',
          yesFunc: async () => {
            this.close(); 
          },
          noFunc:  null
        } 
      } catch(e) {
        console.log('saving/updating: ',e)
        this.alertController.message = 'Something went wrong while uploading batch data. Please try again.'
        this.alertController.show = true;
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
      }
    }



  }
}
</script>

 
