import httpClient from '@/common/http'
export default {
  findAll() {
    return httpClient.request('get', `settings/loan_origination/dsr_management/find-all`)
  },

  update(id, obj) {
    return httpClient.request('put', `settings/loan_origination/dsr_management/${id}`, obj);
  },

  changeIsActive(id, val) {
    return httpClient.request('put', `settings/loan_origination/dsr_management/${id}/active/${val}`);
  },

  changeStopperActive(id, val) {
    return httpClient.request('put', `settings/loan_origination/dsr_management/${id}/stopper-status/${val}`);
  },

  changeCreditScoreStatus(id, val) {
    return httpClient.request('put', `settings/loan_origination/dsr_management/${id}/credit-score-status/${val}`);
  },

  findAllActive() {
    return httpClient.request('get', `settings/loan_origination/dsr_management/findAllActive`);
  },

}