<template>
    <v-layout row wrap>
        <v-dialog v-model="dialog" max-width="800px">
            <v-card>
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-flex>
                            <v-text-field :label="dialogTitle" v-model="newVal"></v-text-field>
                        </v-flex>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialog = false">Close</v-btn>
                    <v-btn @click="validate(),dialog = false">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editDialog" max-width="800px">
            <v-card>
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-flex>
                            <v-text-field :label="dialogTitle" v-model="editedItem.name"></v-text-field>
                        </v-flex>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="editDialog = false">Close</v-btn>
                    <v-btn @click="checkValue(),editDialog = false">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-flex xs6>
            <v-card class="home-card">
                <v-card-title class="fixed-title" style="font-weight: bold;">
                    Home Types
                    <v-btn @click="dialogTitle = 'Add Home Type',dialog=true" class="ml-auto">Add Home Type
                    </v-btn>
                </v-card-title>
                <v-data-table class="mt-1" :items="data.homeType" :headers="homeTypeHeaders" :hide-actions="true">
                    <template slot="items" slot-scope="props">
                        <td>
                            {{ props.item.name | capitalize }}
                        </td>
                        <td title="click to enable for use">
                            <v-checkbox @change="save()" v-model="props.item.required" primary hide-details>
                            </v-checkbox>
                        </td>
                        <td class="justify-center layout px-0">
                            <v-icon small class="mr-2"
                                @click="editItem(props.item,data.homeType,dialogTitle = 'Edit Home Type')">
                                edit
                            </v-icon>
                            <v-icon title="click to delete" small @click="deleteItem(props.item,data.homeType)">
                                delete
                            </v-icon>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-flex xs6>
            <v-card class="business-card">
                <v-card-title class="fixed-title" style="font-weight: bold;">
                    Business Type
                    <v-btn @click="dialogTitle = 'Add Business Type' , dialog=true" class="ml-auto">Add Business
                        Type</v-btn>
                </v-card-title>
                <v-data-table class="mt-1" :items="data.businessType" :headers="businessTypeHeaders" :hide-actions="true">
                    <template slot="items" slot-scope="props">
                        <td title="click to edit">
                            {{ props.item.name | capitalize }}
                        </td>
                        <td title="click to enable for use">
                            <v-checkbox @change="save()" v-model="props.item.required" primary hide-details>
                            </v-checkbox>
                        </td>
                        <td class="justify-center layout px-0">
                            <v-icon small class="mr-2"
                                @click="editItem(props.item,data.businessType,dialogTitle = 'Edit Business Type')">
                                edit
                            </v-icon>
                            <v-icon small @click="deleteItem(props.item,data.businessType)">
                                delete
                            </v-icon>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import YapuService from "@/services/yapu-service";
    export default {
        data() {
            return {
                data: {
                    homeType: [],
                    businessType: [],
                    exposure: [],
                    sensitivity: [],
                    adaptiveCapacity: [],
                },
                dialog: false,
                dialogTitle: null,
                newVal: null,
                homeTypeHeaders: [{
                        text: '*Name',
                        align: 'left',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: "Required?",
                        value: 'required',
                        sortable: false,
                        align: "left"
                    },
                    {
                        text: "Actions",
                        value: "action",
                        sortable: false,
                        align: "center"
                    }
                ],

                businessTypeHeaders: [{
                        text: '*Name',
                        align: 'left',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: "Required?",
                        value: 'required',
                        sortable: false,
                        align: "left"
                    },
                    {
                        text: "Actions",
                        value: "action",
                        sortable: false,
                        align: "center"
                    }
                ],
                selectedArr: [],
                editDialog: false,
                editedIndex: -1,
                editedItem: {
                    name: ''
                },
            };
        },

        watch: {},

        computed: {},

        async mounted() {},

        created() {
            this.initialize();
        },

        methods: {

            async initialize() {
                try {
                    const result = await YapuService.findAll();
                    this.data = result[0] || [];

                    if (this.data.length === 0) {
                        await YapuService.create();
                        const updatedResult = await YapuService.findAll();
                        this.data = updatedResult[0] || [];
                    }
                } catch (error) {
                    console.error(error);
                }
            },

            editItem(item, arr) {
                this.selectedArr = arr;
                this.editedIndex = this.selectedArr.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editDialog = true;
            },

            async checkValue() {
                const type = this.dialogTitle === 'Edit Home Type' ? 'homeType' : 'businessType';
                const trimmedVal = this.editedItem.name.trim();
                const valid = await this.isValueExist(type, trimmedVal)

                if (valid) {
                    this.$toast.error(`The value "${trimmedVal}" already exists in the ${type} list.`, '', {
                        position: 'topLeft'
                    })
                    return;
                }
                if (this.editedIndex > -1) {
                    Object.assign(this.selectedArr[this.editedIndex], this.editedItem)
                } else {
                    this.selectedArr.push(this.editedItem)
                }
                await this.save();
            },

            async validate() {
                const type = this.dialogTitle === 'Add Home Type' ? 'homeType' : 'businessType';
                const trimmedVal = this.newVal.trim();
                const valid = await this.isValueExist(type, trimmedVal)

                if (valid) {
                    this.$toast.error(`The value "${trimmedVal}" already exists in the ${type} list.`, '', {
                        position: 'topLeft'
                    })
                    this.newVal = null;
                    return;
                }
                this.data[type].push({
                    name: trimmedVal
                });
                this.newVal = null;

                await this.save();
            },

            async save() {
                try {
                    await YapuService.update(this.data);
                    this.$toast.success("Record updated successfully", '', {
                        position: 'topLeft'
                    })
                } catch (error) {
                    this.$toast.error("An error occurred while saving data.", '', {
                        position: 'topLeft'
                    })
                    console.error(error);
                }
            },

            async isValueExist(type, val) {
                return this.data[type].some(item => item.name.toLowerCase() === val.toLowerCase());
            },

            async deleteItem(item, arr) {
                const index = arr.indexOf(item)
                this.$toast.question("Are you sure you want to delete this record?", "", {
                    timeout: 20000,
                    close: false,
                    overlay: true,
                    position: "center",
                    buttons: [
                        [
                            "<button><b>YES</b></button>",
                            async (instance, toast) => {
                                    arr.splice(index, 1)
                                    this.save();
                                    instance.hide({
                                        transitionOut: "fadeOut"
                                    }, toast, "button");
                                },
                                true,
                        ],
                        [
                            "<button>NO</button>",
                            function (instance, toast) {
                                instance.hide({
                                    transitionOut: "fadeOut"
                                }, toast, "button");
                            },
                        ],
                    ],
                });
            },
        },
    };
</script>
<style scoped>
    .home-card {
        height: calc(100vh - 200px);
        overflow-y: auto;
        margin-right: 10px;
    }

    .business-card {
        height: calc(100vh - 200px);
        overflow-y: auto;
    }

    .fixed-title {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: rgb(241, 236, 236);
    }
</style>